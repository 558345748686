import { useCookies } from "vue3-cookies";
import { useStore } from "vuex";
import VueJwtDecode from "vue-jwt-decode";
import { findCorrespondingRoute } from "@/helper/role";

export default async function auth({ next, router, to, from }) {
  const cookies = useCookies();
  const store = useStore();
  const token = cookies.cookies.get("token");
  const user = cookies.cookies.get("user");

  if (!token?.id_token) {
    if (process.env.NODE_ENV === "development")
      console.log("Unauthorized: token missing");
    store.dispatch("logout");
    return router.push({
      path: "/login",
    });
  }

  const currentDate = Math.floor(Date.now() / 1000);
  var decodeJwt = VueJwtDecode.decode(token?.id_token);
  const timeRemaining = decodeJwt?.exp ? decodeJwt?.exp - currentDate : 0;

  if (timeRemaining <= 0) {
    // se puede intentar refrescar
    if (process.env.NODE_ENV === "development")
      console.log("Unauthorized: token expired");
    store.dispatch("logout");
    return router.push({
      path: "/login",
    });
  } else if (timeRemaining < 900) {
    // 1 minuto restante refrescar token
    let policy = cookies.cookies.get("policy");
    store.dispatch("initializeAzureAdB2C", policy);
    await store.dispatch("refreshIdToken");
  }

  // valida si puede acceder a la ruta
  if (!findCorrespondingRoute(to.path)) {
    return router.push({
      path: from.path,
    });
  }

  if (process.env.NODE_ENV === "development")
    console.log("Authorized: token valid");

  let brandSelected = cookies.cookies.get("branchSelected");
  if (!brandSelected || typeof brandSelected === "undefined") {
    await store.dispatch("showModalSesion", false);
    await store.dispatch("showModalSesion", true);
  }
  return next();
}
