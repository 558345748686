import { h, resolveComponent } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import log from "@/middleware/log.js";
import auth from "@/middleware/auth.js";

import DefaultLayout from "@/layouts/DefaultLayout.vue";

const routes = [
  {
    path: "/",
    redirect: "/inicio",
    component: DefaultLayout,
    children: [
      {
        path: "/gestion",
        name: "Gestión Oportunidad",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/gestion/oportunidades",
        meta: {
          middleware: [log, auth],
        },
        children: [
          {
            path: "/gestion/oportunidades",
            name: "Gestión Oportunidad",
            component: () =>
              import("@/views/gestionLead/GestionOportunidad.vue"),
          },
          {
            path: "/gestion/nuevaOportunidad",
            name: "Nueva Oportunidad",
            component: () =>
              import("@/views/gestionLead/GestionNuevaOportunidad.vue"),
          },
          {
            path: "/gestion/oportunidad/:id?/:fromCot?/:fromAgenda?/:fromNot?",
            name: "Oportunidad",
            component: () => import("@/views/gestionLead/Oportunidad.vue"),
          },
          {
            path: "/gestion/cotizacion/cotizacion/:id?/:quoteId?/:disabled?",
            name: "Cotizacion",
            component: () =>
              import("@/views/gestionLead/Cotizacion/Cotizacion.vue"),
          },
          {
            path: "/gestion/contacto/contacto/:id?",
            name: "Contacto",
            component: () =>
              import("@/views/gestionLead/Contacto/Contacto.vue"),
          },
        ],
      },
      {
        path: "/renovacion",
        name: "Renovacion",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/renovacion/cabecera",
        meta: {
          middleware: [log, auth],
        },
        children: [
          {
            path: "/renovacion/cabecera",
            name: "Cabecera",
            component: () => import("@/views/renovacion/Cabecera.vue"),
          },
          {
            path: "/renovacion/detalle/:id?/:name?/:origin?",
            name: "Detalle",
            component: () => import("@/views/renovacion/Detalle.vue"),
          },
        ],
      },
      {
        path: "/agenda",
        name: "Agenda",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/agenda/agenda",
        meta: {
          middleware: [log, auth],
        },
        children: [
          {
            path: "/agenda/agenda",
            name: "Agenda",
            component: () => import("@/views/agenda/Agenda.vue"),
          },
        ],
      },
      {
        path: "/reportes",
        name: "Reportes",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/reportes/reporte",
        meta: {
          middleware: [log, auth],
        },
        children: [
          {
            path: "/reportes/reporte",
            name: "Reportes",
            component: () => import("@/views/reportes/Reporte.vue"),
          },
          {
            path: "/reportes/reporteria",
            name: "Reporteria",
            component: () => import("@/views/reportes/Reporteria.vue"),
          },
        ],
      },
      {
        path: "/notificacion",
        name: "Notificacion",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/notificacion/notificaciones",
        meta: {
          middleware: [log, auth],
        },
        children: [
          {
            path: "/notificacion/notificaciones",
            name: "Notificaciones",
            component: () => import("@/views/notificacion/Notificaciones.vue"),
          },
        ],
      },
      {
        path: "/bitacora",
        name: "Bitacora",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/bitacora/bitacora",
        meta: {
          middleware: [log, auth],
        },
        children: [
          {
            path: "/bitacora/bitacora",
            name: "Bitacora",
            component: () => import("@/views/bitacora/Bitacora.vue"),
          },
        ],
      },
      {
        path: "/configuracion",
        name: "Configuración",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/configuracion/configuracion",
        meta: {
          middleware: [log, auth],
        },
        children: [
          {
            path: "/configuracion/configuracion",
            name: "Configuracion",
            component: () => import("@/views/configuracion/Configuracion.vue"),
          },
        ],
      },
      {
        path: "/mantenedor",
        name: "Mantenedor",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/mantenedor/producto",
        meta: {
          middleware: [auth, log],
        },
        children: [
          {
            path: "/mantenedor/producto",
            name: "Producto",
            component: () => import("@/views/mantenedor/Producto.vue"),
          },
          {
            path: "/mantenedor/mantenedorProducto/:id?",
            name: "Mantenedor Producto",
            component: () =>
              import("@/views/mantenedor/MantenedorProducto.vue"),
          },
          {
            path: "/mantenedor/categoria",
            name: "Categoria",
            component: () => import("@/views/mantenedor/Categoria.vue"),
          },
          {
            path: "/mantenedor/tipo",
            name: "Tipo",
            component: () => import("@/views/mantenedor/Tipo.vue"),
          },
          {
            path: "/mantenedor/dealer",
            name: "Dealer",
            component: () => import("@/views/mantenedor/Dealer.vue"),
          },
          {
            path: "/mantenedor/mantenedorDealer/:id?",
            name: "Mantenedor Dealer",
            component: () => import("@/views/mantenedor/MantenedorDealer.vue"),
          },
          {
            path: "/mantenedor/sla",
            name: "SLA",
            component: () => import("@/views/mantenedor/Sla.vue"),
          },
          {
            path: "/mantenedor/tiposla",
            name: "TipoSLA",
            component: () => import("@/views/mantenedor/TipoSla.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/inicio",
    name: "Inicio",
    meta: {
      middleware: [log, auth],
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Inicio.vue"),
  },
  {
    path: "/authentication",
    name: "Authentication",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/pages/Authentication.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/pages/Login.vue"),
  },
  // {
  //   path: "/login",
  //   name: "Maintenance",
  //   component: () =>
  //     import(/* webpackChunkName: "home" */ "@/views/pages/Maintenance.vue"),
  // },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/views/pages/NotFound"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
