<template>
  <CCard :class="'usercard-user-profile-' + background">
    <CRow class="usercard-container px-1">
      <CCol :md="12" class="d-flex">
        <UserCircle2 class="usercard-icon align-self-center" />
        <CContainer class="usercard-info p-1">
          <CFormLabel class="pt-1"
            ><b>{{ nombreUsuario }}</b> - <small>{{ position }}</small
            ><br />
            <small class="text-unbrake">{{
              branchSelected.branch.fantasyName
            }}</small></CFormLabel
          >
          <CFormLabel class="text-unbrake">{{ correo }}</CFormLabel>
        </CContainer>
        <LogOut
          @click="logout"
          class="usercard-logout-icon align-self-center"
        />
      </CCol>
      <div
        class="text-xs-medium justify-content-center d-flex"
        :class="background == 'black' ? 'text-white' : ''"
      >
        Versión: {{ version }}
      </div>
    </CRow>
  </CCard>
</template>

<script>
import { onMounted, reactive, toRefs, computed } from "vue";
import router from "@/router/index";
import UserName from "@/components/shared/UserName.vue";
import { UserCircle2, LogOut } from "lucide-vue-next";
import { useCookies } from "vue3-cookies";
import { useStore } from "vuex";

export default {
  name: "DefaultLayout",
  components: {
    UserCircle2,
    LogOut,
    UserName,
  },
  props: {
    background: {
      type: String,
    },
  },
  setup() {
    const cookies = useCookies();
    const store = useStore();

    const version = process.env.VUE_APP_TAG_FRONT;
    const state = reactive({
      nombreUsuario: "",
      correo: "",
      position: "",
      classCorreo: "",
      branchSelected: computed(() => store.state.branchSelected),
    });

    onMounted(() => {
      try {
        const user = cookies.cookies.get("user");
        if (user) {
          state.nombreUsuario = user.firstName + " " + user.lastName;
          state.correo = user.mail;
          state.position = user.position;
        }
      } catch (error) {}
    });

    const logout = async () => {
      await store.dispatch("logout");
      return router.push({
        path: "/login",
      });
    };
    return {
      ...toRefs(state),
      logout,
      version,
    };
  },
};
</script>

<style scoped>
.text-unbrake {
  /*word-wrap: normal !important;*/
}
</style>
