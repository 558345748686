import ApiMss from "@/api/ApiMss";
import ApiPortal from "@/api/ApiPortal";
import ApiTwilio from "@/api/ApiTwilio";
import { objectToQueryString } from "@/helper/util";
import { useCookies } from "vue3-cookies";

const oportunidadService = {
  getOportunidadFilter,
  getOportunidadById,
  getOportunidadByRut,
  generarOportunidad,
  generarCotizacion,
  asignarVendedorOportunidad,
  getDatosContacto,
  cambioFase,
  financiamiento,
  vpp,
  getOportunidadByRutCliente,
  getOportunidadExpress,
  getHistoryContact,
  sendNotificationAsignarOportunidadVendedor,
  getHistorialEstadosOportunidad,
};

async function getOportunidadExpress(rutCliente, schedule) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  const user = cookies.cookies.get("user");

  /*return await ApiMss.get(
    `/mss-core/v1/opportunities/express?Dealers=${branchSelected.branch.dealer._id}&Branches=${branchSelected.branch._id}&Rut=${rutCliente}&IdsFaseOportunidad=${schedule.states}&RutVendedor=${user.rut}`
  );*/
  return await ApiMss.get(
    `/mss-core/v1/opportunities/express?Dealers=${branchSelected.branch.dealer._id}&Branches=${branchSelected.branch._id}&Rut=${rutCliente}&RutVendedor=${user.rut}`
  );
}

async function getOportunidadByRutCliente(filter) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  filter.BrandId = branchSelected.branch.dealer.brand._id;
  filter.IdDealer = branchSelected.branch.dealer._id;
  filter.MostrarDetalle = true;
  const queryString = objectToQueryString(filter);
  return await ApiMss.get(`/mss-core/v1/opportunities/list?${queryString}`);
}

async function getOportunidadById(id) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  return await ApiMss.get(
    `/mss-core/v1/opportunities/list?BrandId=` +
      branchSelected.branch.dealer.brand._id +
      "&OpportunityId=" +
      id
  );
}

async function getOportunidadByRut(rut) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  return await ApiMss.get(
    `/mss-core/v1/opportunities/list?BrandId=` +
      branchSelected.branch.dealer.brand._id +
      "&Rut=" +
      rut
  );
}

async function getOportunidadFilter(filter) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  const user = cookies.cookies.get("user");
  filter.BrandId = branchSelected.branch.dealer.brand._id;
  filter.IdDealer = branchSelected.branch.dealer._id;
  filter.IdSucursal = filter.IdSucursal
    ? filter.IdSucursal
    : user.position.toLowerCase().includes("call_center")
    ? null
    : branchSelected.branch._id;
  filter.IdRole = user.roleId;
  filter.MostrarDetalle = true;
  let sinAsignar = false;
  if (filter.RutVendedor === "") sinAsignar = true;

  if (user.position.toLowerCase().includes("call_center")) {
    filter.IdOrigen =
      "100000012,100000018,100000038,100000039,100000039,100000031,100000028,100000045";
    if (!filter.IdFaseOportunidad)
      filter.IdFaseOportunidad = "100000000,100000001,100000002";
    if (filter.IdFaseOportunidad && !filter.IdSubFaseOportunidad) {
      filter.IdSubFaseOportunidad = "";
    } else if (!filter.IdSubFaseOportunidad) {
      filter.IdSubFaseOportunidad =
        "100000000,100000002,100000003,100000004,100000005,100000006";
    }
  }

  let queryString = objectToQueryString(filter);
  if (sinAsignar) queryString += "&RutVendedor=";

  /*
  if (
    user.position.toLowerCase().includes("jefe_sucursal") &&
    !filter.IdFaseOportunidad
  ) {
    queryString +=
      "&IdFaseOportunidad=100000000,100000001,100000002,100000003,100000004,100000005,100000006,100000007,100000008";
  }*/

  if (user.position.toLowerCase().includes("vendedor")) {
    if (queryString.includes("&RutVendedor="))
      queryString.replace("&RutVendedor=", "&RutVendedor=" + user.rut);
    else queryString += "&RutVendedor=" + user.rut;
  }

  queryString = queryString.replace(/%2C/g, ",");

  return await ApiMss.get(
    `/mss-core/v1/opportunities/role/count?` + queryString
  );
}

async function generarOportunidad(oportunidad) {
  const cookies = useCookies();
  const user = cookies.cookies.get("user");
  const branchSelected = cookies.cookies.get("branchSelected");

  oportunidad.RutEjecutivo = user.rut;
  oportunidad.IdConcesionario = branchSelected.branch.IdSucursal;
  oportunidad.Origen = process.env.VUE_APP_ORIGEN;
  return await ApiMss.post(`/mss-crm/v1/GenerateOpportunity`, oportunidad);
}

async function asignarVendedorOportunidad(body) {
  /*
    {
    "RUT":"16615845-1",
    "OppID":"af4c064b-bb1b-ee11-8f6d-002248365d6c",
    "FromMongoDB":false
    }
    */
  return await ApiMss.post(`/mss-crm/v1/AssignRUTToOpp`, body);
}

async function getDatosContacto() {
  return await ApiMss.get(`/mss-crm/v1/GetContactData`);
}

async function cambioFase(body) {
  const cookies = useCookies();
  const user = cookies.cookies.get("user");
  const branchSelected = cookies.cookies.get("branchSelected");
  body.DatosEntrada.Vendedor = body.DatosEntrada.Vendedor
    ? body.DatosEntrada.Vendedor
    : user.rut;
  body.DatosEntrada.concesionario = body.DatosEntrada.concesionario
    ? body.DatosEntrada.concesionario
    : branchSelected.branch.IdSucursal;
  return await ApiMss.post(`/mss-crm/v1/CambiosFase`, body);
}

async function generarCotizacion(cotizacion) {
  return await ApiMss.post(`/mss-crm/v1/GenerateQuote`, cotizacion);
}

async function financiamiento(body) {
  // Datos en duro
  body.Cotizacion_valida_hasta = null;
  body.Seguro_desgravamen = null;
  body.Seguro_trimax = null;
  body.Seguro_cuota_protegida = null;
  body.Seguro_viaje = null;
  return await ApiMss.post(`/mss-crm/v1/Financiamiento`, body);
}

async function vpp(body) {
  return await ApiMss.post(`/mss-crm/v1/VPP`, body);
}

async function getHistoryContact(OpportunityId) {
  return await ApiMss.get(
    `/mss-crm/v1/crm/list/history/changes/contact?OpportunityId=${OpportunityId}`
  );
}

async function sendNotificationAsignarOportunidadVendedor(body) {
  body.type = 3;
  body.subType = 1;
  body.notification = true;

  return await ApiPortal.post(`/mss/notification/send`, body);
}

async function getHistorialEstadosOportunidad(OpportunityId) {
  return await ApiTwilio.get(
    `opportunity/list/chages/states/by/filter?OpportunityId=${OpportunityId}`
  );
}

export default oportunidadService;
