import { defineComponent, computed, h } from 'vue';
import { CBadge } from '@coreui/vue';

export const NotificationCounter = defineComponent({
  name: 'NotificationCounter',
  props: {
      type: String,
      counter: Number
  },
  setup(props) {
    const computedStyle = computed(() => {
      const styles = {
        danger: 'rounded-circle-danger'
      };

      if (props.type) {
        return styles[props.type] || styles.info;
      }
    });
    
    return () => h(CBadge, {
      shape: computedStyle.value ? computedStyle.value : ''
    }, props.counter);
  }
})
