<template>
  <p class="fs-5">Bienvenido, {{ nombreUsuario }}</p>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue";
import { useCookies } from "vue3-cookies";

export default {
  name: "UserName",
  setup() {
    const cookies = useCookies();
    const state = reactive({
      nombreUsuario: "",
    });

    onMounted(() => {
      try {
        const user = cookies.cookies.get("user");
        if (user) {
          state.nombreUsuario = user.firstName + " " + user.lastName;
        }
      } catch (error) {}
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style></style>
