<template>
  <div
    :class="sidebarUnfoldable ? 'sidebar-fixed' : 'sidebar'"
    @mouseenter="
      $store.commit({
        type: 'toggleIsMouseOver',
        value: true,
      })
    "
    @mouseleave="
      $store.commit({
        type: 'toggleIsMouseOver',
        value: false,
      })
    "
  >
    <CSidebar
      id="sidebar"
      position="fixed"
      :unfoldable="sidebarUnfoldable"
      :visible="sidebarVisible"
      @visible-change="
        (event) =>
          $store.commit({
            type: 'updateSidebarVisible',
            value: event,
          })
      "
    >
      <CContainer
        class="unfoldable-nav"
        @click="$store.commit('toggleUnfoldable')"
      >
        <AlignJustify :size="17" v-if="!sidebarUnfoldable" />
        <AlignLeft :size="17" v-else />
      </CContainer>
      <CSidebarBrand>
        <img
          :src="branchSelected.branch.dealer.brand.whiteLogo"
          class="logo mb-2"
        />
      </CSidebarBrand>
      <div v-if="isMouseOver || !sidebarUnfoldable" class="p-3">
        <InputSearch
          :background="'black'"
          :toSearch="toSearch"
          @update:toSearch="toSearch = $event"
        />
      </div>
      <AppSidebarNav
        :class="isMouseOver || !sidebarUnfoldable ? 'sidebar-items' : ''"
      />
      <CContainer
        class="sidebar-container"
        :class="isMouseOver || !sidebarUnfoldable ? 'px-3' : ''"
      >
        <ul class="sidebar-nav">
          <li class="nav-item text-white">
            <a
              class="nav-link sidebar-nav-link sidebar-configuration"
              @click="ir('Configuracion')"
            >
              <Settings />
              <CFormLabel
                v-if="isMouseOver || !sidebarUnfoldable"
                class="sidebar-label text-sm-medium"
              >
                Configuración
              </CFormLabel></a
            >
          </li>
        </ul>
      </CContainer>
      <CContainer class="nav-separation mb-1 mt-1"> </CContainer>
      <CSidebarToggler
        :class="
          isMouseOver || !sidebarUnfoldable ? '' : 'user-profile-icon-container'
        "
        class="mb-3"
      >
        <UserCard
          v-if="isMouseOver || !sidebarUnfoldable"
          :background="'black'"
          class="text-white px-2"
        />
        <UserCircle2 v-else class="user-profile-icon-nav" />
      </CSidebarToggler>
    </CSidebar>
  </div>
</template>

<script>
import router from "@/router";
import { computed, reactive, watch, toRefs, nextTick, ref } from "vue";
import { useStore } from "vuex";
import { AppSidebarNav } from "./AppSidebarNav";
import { logoNegative } from "@/assets/brand/logo-negative";
import { sygnet } from "@/assets/brand/sygnet";
import { CSidebar } from "@coreui/vue";
import logoKiaWhite from "@/assets/images/logo-kia-white.svg";
import {
  Search,
  Settings,
  UserCircle2,
  AlignJustify,
  AlignLeft,
} from "lucide-vue-next";
import InputSearch from "@/components/shared/InputSearch.vue";
import UserCard from "@/components/shared/UserCard.vue";
import { useCookies } from "vue3-cookies";

export default {
  name: "AppSidebar",
  components: {
    AppSidebarNav,
    CSidebar,
    Search,
    Settings,
    UserCircle2,
    InputSearch,
    UserCard,
    AlignJustify,
    AlignLeft,
  },
  setup() {
    const store = useStore();

    const cookies = useCookies();
    const branchSelected = ref(cookies.cookies.get("branchSelected"));

    const state = reactive({
      toSearch: store.state.toSearchSidebar,
    });

    const ir = (route) => {
      return router.push({
        name: route,
      });
    };

    watch(
      () => store.state.isMouseOver.value,
      () => {
        nextTick(() => {
          if (store.state.isMouseOver.value) {
            document.getElementById("inputSearch").value =
              store.state.toSearchSidebar;
            state.toSearch = store.state.toSearchSidebar;
          }
        });
      }
    );

    watch(
      () => state.toSearch,
      () => {
        store.commit({
          type: "updateToggleSidebarSearch",
          value: state.toSearch,
        });
        if (!document.querySelectorAll(".find-sidebar-group.show").length) {
          let sidebargroup = document.querySelectorAll(
            ".find-sidebar-group"
          )[0];
          if (sidebargroup)
            sidebargroup.querySelector(".nav-link.nav-group-toggle").click();
        }

        nextTick(() => {
          const listItems = document.querySelectorAll(".find-sidebar");
          const groupItems = document.querySelectorAll(".find-sidebar-group");

          const allItems = [...listItems, ...groupItems];

          allItems.forEach((item) => {
            const textContent = item.textContent.trim().toLowerCase();
            const toSearchLowerCase = state.toSearch.toLowerCase();

            if (textContent.includes(toSearchLowerCase)) {
              item.classList.remove("d-none");
            } else {
              item.classList.add("d-none");
            }
          });
        });
      }
    );

    return {
      ...toRefs(state),
      ir,
      logoNegative,
      sygnet,
      logoKiaWhite,
      branchSelected,
      isMouseOver: computed(() => store.state.isMouseOver.value),
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    };
  },
};
</script>
