import axios from "axios";
import ApiMss from "@/api/ApiMss";
import { useCookies } from "vue3-cookies";

const axiosInstance = axios.create({
  baseURL: "https://api-public-chatbot-indumotora-qa.gatblac.com",
  headers: {
    Authorization:
      "QivwNOuWkTI8PJGfB!sc03EQg!bJ8u0d-tFxATQ/qzNqjb8iW1viBd6eFFP1hSh=fZhyR0C0VT9fnKwjC9iEMo3XSApdm6Xd1oeRjzHO4qZ8sCDLRIa86wcbiARMI1?-vAi=o4nUFQf?JZKUI0VTHnO4Knflds2StYXewxHtPpMCi2mExrNMCt?e1wGlHb9t6eRbf?kYA0hSUybGJOfnhSe7LSZ4Mywd5iQx9XXO1eHmxy2E0-A8mUHX-uw=k5Yr",
  },
});

const whatsappService = {
  getUltimasConversaciones,
  getIndicadoresWhatsapp,
};

async function getUltimasConversaciones(timeAgo = 24) {
  const cookies = useCookies();
  const user = cookies.cookies.get("user");
  if (!user?.id) return;
  let body = {
    executive: user.id,
    lastMessageTimeAgo: timeAgo,
  };
  return await ApiMss.post(
    `/mss-core/v1/singleChat/lastConversationsCollaborator`,
    body
  );
}

async function getIndicadoresWhatsapp(
  dateFrom,
  dealerId,
  branchId,
  collaboratorId
) {
  const cookies = useCookies();
  const user = cookies.cookies.get("user");
  if (!user?.id) return;
  let body = {
    company: "kia",
    dateFrom,
    dealerId,
    branchId,
    collaboratorId,
  };
  return await ApiMss.get(`/mss-core/v1/metrics/getMetricsDealers`, body);
}

export default whatsappService;
