import ApiMss from "@/api/ApiMss";
import { useCookies } from "vue3-cookies";

const qrService = {
  getQrById,
  upsertQr,
};

async function getQrById(Id) {
  return await ApiMss.get(`/mss-core/v1/qr/list?_id=${Id}`);
}

async function upsertQr(body) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  body.type = 1;
  body.dealerId = branchSelected.branch.dealer._id;
  return await ApiMss.post(`/mss-core/v1/qr/upsert`, body);
}

export default qrService;
