<template>
  <CModal
    alignment="center"
    size="xl"
    :visible="visible"
    @close="
      () => {
        $store.commit('resetCustomerHistoryModal');
      }
    "
  >
    <CModalHeader>
      <CRow>
        <CCol class="selectmodal-container">
          <CRow>
            <CCol>
              <!-- <History class="icon-filter" size="30" /> -->
              <b> {{ content.title }} </b>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CModalHeader>
    <CModalBody class="">
      <CRow>
        <CCol>
          <CTable class="table-oportunidad" hover>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell
                  scope="col"
                  class="text-start text-xs-medium-gray"
                  >&nbsp;</CTableHeaderCell
                >
                <CTableHeaderCell
                  scope="col"
                  class="text-start text-xs-medium-gray"
                  >Nombres&nbsp;</CTableHeaderCell
                >
                <CTableHeaderCell
                  scope="col"
                  class="text-start text-xs-medium-gray"
                  >Apellidos&nbsp;</CTableHeaderCell
                >
                <CTableHeaderCell
                  scope="col"
                  class="text-center text-xs-medium-gray"
                  >Correo</CTableHeaderCell
                >
                <CTableHeaderCell
                  scope="col"
                  class="text-center text-xs-medium-gray"
                  >Teléfono</CTableHeaderCell
                >
                <CTableHeaderCell
                  scope="col"
                  class="text-center text-xs-medium-gray"
                  >Creado</CTableHeaderCell
                >
                <CTableHeaderCell
                  scope="col"
                  class="text-center text-xs-medium-gray"
                  >Modificado por</CTableHeaderCell
                >
                <CTableHeaderCell
                  scope="col"
                  class="text-center text-xs-medium-gray"
                  >Cambio</CTableHeaderCell
                >
              </CTableRow>
            </CTableHead>
            <CTableBody>
              <CTableRow v-if="!displayedContacts.length" color="info">
                <CTableDataCell colspan="9" class="text-center"
                  ><strong
                    >No existen datos de contacto históricos</strong
                  ></CTableDataCell
                >
              </CTableRow>
              <CTableRow
                v-for="(contact, index) in displayedContacts"
                :key="contact._id"
              >
                <CTableDataCell
                  scope="row"
                  class="text-star text-xs-medium-black"
                >
                  &nbsp;
                </CTableDataCell>
                <CTableDataCell
                  scope="row"
                  class="text-star text-xs-medium-black"
                >
                  <b> {{ contact.firstName }} </b>
                </CTableDataCell>
                <CTableDataCell
                  scope="row"
                  class="text-star text-xs-medium-black"
                >
                  <b> {{ contact.lastName }} </b>
                </CTableDataCell>
                <CTableDataCell class="text-start text-sm-medium-gray"
                  >{{ contact.email
                  }}<CopyButton
                    @click.stop
                    :size="'14'"
                    :class="''"
                    :valueCopy="contact.email"
                  ></CopyButton
                ></CTableDataCell>
                <CTableDataCell class="text-center text-xs-medium-gray">
                  {{ contact.phone }}
                </CTableDataCell>
                <CTableDataCell class="text-center text-xs-medium-gray">
                  {{ formattedDate(contact.date, "DD-MM-yyyy") }}
                </CTableDataCell>
                <CTableDataCell class="text-center text-xs-medium-gray">
                  {{ contact.user }}
                </CTableDataCell>
                <CTableDataCell class="text-center text-xs-medium-gray">
                  {{ contact.change }}
                </CTableDataCell>
              </CTableRow>
            </CTableBody>
            <CTableHead>
              <CTableRow>
                <CTableDataCell colspan="10"
                  ><CRow>
                    <CCol xs="auto" class="me-auto"
                      ><div
                        class="d-grid gap-2 d-md-flex justify-content-md-start custom-padding-gap"
                      >
                        <CButton
                          @click="prevPage"
                          :disabled="state.paginator.actualPage === 1"
                          class="btn-pagination text-sm-medium-gray me-md-2"
                          >Anterior</CButton
                        >
                        <CButton
                          @click="nextPage"
                          :disabled="state.paginator.actualPage === totalPages"
                          class="btn-pagination text-sm-medium-gray"
                          >Siguiente</CButton
                        >
                      </div></CCol
                    >
                    <CCol xs="auto text-center txt-pagination pe-4"
                      >Página {{ state.paginator.actualPage }} de
                      {{ totalPages }}</CCol
                    >
                  </CRow></CTableDataCell
                ></CTableRow
              >
            </CTableHead>
          </CTable>
        </CCol>
      </CRow>
    </CModalBody>
  </CModal>
</template>

<script>
import { computed, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { History } from "lucide-vue-next";
import { formattedDate } from "@/helper/util.js";
import CopyButton from "@/components/shared/CopyButton.vue";

export default {
  components: {
    History,
    CopyButton,
  },
  setup() {
    const store = useStore();
    const state = reactive({
      paginator: {
        actualPage: 1,
        pages: 1,
        nextPage: "",
        perPage: 5,
      },
    });

    const totalPages = computed(() => {
      return Math.ceil(
        store.state.customerHistoryContent.Contacts.length /
          state.paginator.perPage
      );
    });

    const resetChecked = () => {
      // Cambiar todos los checkboxes con name 'chk-quotes-all' a false
      document
        .querySelectorAll('input[name="chk-quotes-all"]')
        .forEach((checkbox) => (checkbox.checked = false));

      // Cambiar todos los checkboxes con name 'chk-quotes' a false
      document
        .querySelectorAll('input[name="chk-quotes"]')
        .forEach((checkbox) => (checkbox.checked = false));
    };

    const displayedContacts = computed(() => {
      const start = (state.paginator.actualPage - 1) * state.paginator.perPage;
      let slicedContacts = store.state.customerHistoryContent.Contacts.slice(
        start,
        start + state.paginator.perPage
      );
      return slicedContacts.sort();
    });

    const nextPage = () => {
      if (state.paginator.actualPage < totalPages.value) {
        state.paginator.actualPage += 1;
        resetChecked();
      }
    };

    const prevPage = () => {
      if (state.paginator.actualPage > 1) {
        state.paginator.actualPage -= 1;
        resetChecked();
      }
    };

    return {
      state,
      totalPages,
      nextPage,
      prevPage,
      displayedContacts,
      content: computed(() => store.state.customerHistoryContent),
      visible: computed(() => store.state.visibleCustomerHistoryModal),
      formattedDate,
    };
  },
};
</script>
