import ApiRenovation from "@/api/ApiRenovation";
import ApiMss from "@/api/ApiMss";
import { objectToQueryString } from "@/helper/util";
import { useCookies } from "vue3-cookies";

// servicios expuestos
const renovationService = {
  getCampaigns,
  getHeaders,
  addHeaderRenovation,
  addDetailRenovation,
  getDetailRenovation,
  getOriginList,
};

async function getHeaders(filter) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  const user = cookies.cookies.get("user");
  let origin = "";
  if (user.position.toLowerCase().includes("f&i")) {
    origin = (await getOriginList()).data.origins.find(
      (x) => x.name == "FORUM"
    )._id; //CAMBIAR A FORUM
  }
  if (
    user.position.toLowerCase().includes("jefe_sucursal") ||
    user.position.toLowerCase().includes("administrador")
  ) {
    const originIds = (await getOriginList()).data.origins.map((x) => x._id);
    origin = originIds.join(",");
  }
  filter.branch = branchSelected.branch._id;
  filter.dealer = branchSelected.branch.dealer._id;
  let queryString = objectToQueryString(filter);

  if (origin != "") queryString += "&origins=" + origin;

  return await ApiRenovation.get(
    `/mss-renovations/v1/headers/list?` + queryString
  );
}

async function getDetailRenovation(filter) {
  //filter.origin = (await getOriginList()).data.origins.find((x) => x.name == 'MSS')._id
  filter.active = true;
  const queryString = objectToQueryString(filter);
  return await ApiRenovation.get(
    `/mss-renovations/v1/renovations/listDetail?${queryString}`
  );
}

async function addHeaderRenovation(body) {
  const cookies = useCookies();
  const user = cookies.cookies.get("user");
  const branchSelected = cookies.cookies.get("branchSelected");
  body.user = user.id;
  body.branch = branchSelected.branch._id;
  body.dealer = branchSelected.branch.dealer._id;
  body.origin = (await getOriginList()).data.origins.find(
    (x) => x.name == "MSS"
  )._id;
  return await ApiRenovation.post(`/mss-renovations/v1/headers/upsert`, body);
}

async function addDetailRenovation(header, renovationsBatch) {
  renovationsBatch.forEach((item) => {
    // Eliminar el campo user de cada objeto
    /* item.distributor = "Distributor C";
    item.originBranch = "633495900d4fd017470c4f82";
    item.originBranchName = "Automotora Berríos - Castro";
    item.destinationBranch = "633495900d4fd017470c4fd5";
    item.destinationBranchName = "Callegari coquimbo";
    item.contractNumber = "542-335-6663";*/
    item.contractStatus = "";
    // item.expirationDate = "9/12/2019";
    // item.product = "item 1";
    /*item.brand = "KIA";
    item.vehicleStatus = null;
    item.year = 1980;
    item.footPrice = 246459;
    item.term = 64;
    item.currentFee = 156725;
    item.vfmg = 443671;
    item.km = 961340;
    item.ppu = null;
    item.field_1 = null;
    item.field_2 = null;
    item.field_3 = null;
    item.contract_Status = null;
    item.dealer = "27990000";
    item.nameDealer = "AUTOMOTORA ALAMEDA LTDA.";
    item.active = false;*/
    /*item.createdAt = "8/25/2021";
    item.updatedAt = "10/23/2024";*/
    delete item.codversion;
    delete item.error;
    delete item.errorDesc;
    delete item.fechacarga;
    delete item.modeloId;
    delete item.orden;
    delete item.versionId;
  });
  let body = {
    header: header,
    renovationsBatch: renovationsBatch,
  };
  return await ApiRenovation.post(
    `/mss-renovations/v1/renovations/insertManual`,
    body
  );
}

async function getOriginList() {
  return await ApiRenovation.get(`/mss-renovations/v1/origins/list`);
}

async function getCampaigns() {
  return await ApiRenovation.get(`/mss-renovations/v1/campaigns/list`);
}

export default renovationService;
