import { defineComponent, h, onMounted, ref, resolveComponent } from 'vue';
import { RouterLink, useRoute } from 'vue-router';
import { CBadge, CSidebarNav, CNavGroup, CNavTitle, CNavItem } from '@coreui/vue';
import nav from '@/_nav.js';
import { NotificationCounter } from './NotificationCounter';
import { useStore } from 'vuex';
import { findCorrespondingRoute } from '@/helper/role';

const normalizePath = (path) =>
    decodeURI(path)
        .replace(/#.*$/, '')
        .replace(/(index)?\.(html)$/, '');

const isActiveLink = (route, link) => {
    if (link === undefined) {
        return false;
    }

    if (route.hash === link) {
        return true;
    }

    const currentPath = normalizePath(route.path);
    const targetPath = normalizePath(link);

    return currentPath === targetPath;
};

const isActiveItem = (route, item) => {
    if (isActiveLink(route, item.to)) {
        return true;
    }

    if (item.items) {
        return item.items.some((child) => isActiveItem(route, child));
    }

    return false;
};

const AppSidebarNav = defineComponent({
    name: 'AppSidebarNav',
    components: {
        CNavItem,
        CNavGroup,
        CNavTitle,
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const firstRender = ref(true);

        onMounted(() => {
            firstRender.value = false;
        });

        const renderItem = (item) => {
            if (item.items) {
                return h(
                    CNavGroup,
                    {
                        ...(firstRender.value && {
                            visible: item.items.some((child) => isActiveItem(route, child)),
                        }),
                        id: item.id,
                        class: 'text-sm-medium find-sidebar-group',
                    },
                    {
                        togglerContent: () => [
                            h(item.icon, {
                                class: 'nav-icon',
                                style: "fill:none",
                                name: item.icon,
                            }),
                            item.name,
                        ],
                        default: () => item.items.map((child) => renderItem(child)),
                    }
                );
            }
            return item.to
                ? h(
                      RouterLink,
                      {
                          to: item.to,
                          custom: true,
                      },
                      {
                          default: (props) =>
                              h(
                                  resolveComponent(item.component),
                                  {
                                      active: props.isActive,
                                      href: props.href,
                                      class: 'text-sm-medium find-sidebar',
                                      onClick: (event) => {
                                        if (item.name === 'Notificaciones') {
                                            store.commit('updateIsNewToast', false);
                                        }
                                        
                                        return props.navigate(event);
                                      },
                                      id: item.id
                                  },
                                  {
                                      default: () => [
                                          item.icon &&
                                              h(item.icon, {
                                                  class: 'nav-icon',
                                                  name: item.icon,
                                                  style: "fill:none;",
                                              }),
                                          item.name,
                                          item.badge &&
                                              h(
                                                  CBadge,
                                                  {
                                                      class: 'ms-auto',
                                                      color: item.badge.color,
                                                  },
                                                  {
                                                      default: () => item.badge.text,
                                                  }
                                              ),
                                              (item.name === 'Notificaciones') &&
                                              store.getters.isNewToasts && (store.getters.getToastsCount > 0)
                                              ? [
                                                    h('div', { style: { padding: '5px' }}),
                                                    h(NotificationCounter, { type: 'danger', counter: store.getters.getToastsCount })
                                                ]
                                              : ''
                                      ],
                                  }
                              ),
                      }
                  )
                : h(
                      resolveComponent(item.component),
                      {},
                      {
                          default: () => item.name,
                      }
                  );
        };

        return () =>
            h(
                CSidebarNav,
                {},
                {
                    default: () => nav.map((item) => findCorrespondingRoute(item.to, item.items) ? renderItem(item) : null) ,
                }
            );
    },
});
export { AppSidebarNav };
