<template>
  <CContainer class="overlay-modal">
    <div v-if="visibleAlertModal" class="overlay"></div>
    <CModal
      size="lg"
      alignment="center"
      :visible="visibleAlertModal"
      @close="
        () => {
          $store.commit('resetAlert');
        }
      "
    >
      <CModalHeader class="modal-header-custom"> </CModalHeader>
      <CModalBody class="alertmodal-body">
        <CContainer class="alertmodal-container">
          <CRow>
            <CCol
              class="alertmodal-container-icon-success"
              v-if="alertContent.icon == 'success'"
            >
              <CheckCircle class="alertmodal-icon-success" />
            </CCol>
            <CCol
              class="alertmodal-container-icon-warning"
              v-if="alertContent.icon == 'warning'"
            >
              <AlertCircle class="alertmodal-icon-warning" />
            </CCol>
            <CCol
              class="alertmodal-container-icon-success-yellow"
              v-if="alertContent.icon == 'success-yellow'"
            >
              <CheckCircle class="alertmodal-icon-success-yellow" />
            </CCol>
          </CRow>
          <CContainer class="d-grid">
            <CFormLabel class="fw-bold"> {{ alertContent.title }} </CFormLabel>
            <CFormLabel v-if="alertContent.content" class="text-sm-medium">
              {{ alertContent.content }}
            </CFormLabel>
          </CContainer>
        </CContainer>
        <CRow v-if="alertContent.confirmButtonText" :xs="{ gutterX: 5 }">
          <CCol class="d-grid">
            <CButton class="btn-white text-md-semibold" @click="cancelar"
              >Cancelar
            </CButton>
          </CCol>
          <CCol class="d-grid">
            <CButton class="btn-primary text-md-semibold"
              >{{ alertContent.confirmButtonText }}
            </CButton>
          </CCol>
        </CRow>
      </CModalBody>
    </CModal>
  </CContainer>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { AlertCircle, CheckCircle } from "lucide-vue-next";

export default {
  name: "AlertModal",
  components: {
    AlertCircle,
    CheckCircle,
  },
  setup() {
    const store = useStore();
    return {
      alertContent: computed(() => store.state.alertContent),
      visibleAlertModal: computed(() => store.state.visibleAlertModal),
    };
  },
};
</script>
