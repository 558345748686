import ApiAuthenticate from "@/api/ApiAuthenticate";
import ApiMsal from "@/api/ApiMsal";

// servicios expuestos
const loginService = {
  authentication,
  verifyToken,
  regenerateToken,
  authenticationMsal,
};

async function authenticationMsal() {
  const loginRequest = {
    scopes: ["User.Read"],
  };
  await ApiMsal.loginPopup(loginRequest);
}

async function authentication(body) {
  return await ApiAuthenticate.post("/authentication/general", body);
}

async function verifyToken(token) {
  return await ApiAuthenticate.get("/authentication/token/verify", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

async function regenerateToken(token) {
  return await ApiAuthenticate.get("/authentication/token/regenerate", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export default loginService;
