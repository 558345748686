import ApiMss from "@/api/ApiMss";
import { objectToQueryString } from "@/helper/util";

// servicios expuestos
const logService = {
  getLog,
};

async function getLog(filter) {
  const queryString = objectToQueryString(filter);
  return await ApiMss.get(`/mss-core/v1/logServices/list?` + queryString);
}

export default logService;
