<template>
  <div>
    <ModalAlert />
    <AppSidebar class="nav-container" />
    <div class="wrapper d-flex flex-column max-vh-100 bg-container-app">
      <AppHeader />
      <div class="body flex-grow-1 px-5 bg-white">
        <CContainer fluid class="px-5">
          <router-view />
        </CContainer>
      </div>
      <AppFooter />
    </div>
  </div>
</template>
<script>
import { CContainer } from "@coreui/vue";
import AppHeader from "@/components/AppHeader.vue";
import AppSidebar from "@/components/AppSidebar.vue";
import AppFooter from "@/components/AppFooter.vue";
import ModalAlert from "@/components/shared/AlertModal.vue";

export default {
  name: "DefaultLayout",
  components: {
    AppHeader,
    AppSidebar,
    CContainer,
    AppFooter,
    ModalAlert,
  },
  setup() {
    return {};
  },
};
</script>
