<template>
  <CModal alignment="center" :visible="loading.visible" backdrop="static">
    <CModalBody class="text-center">
      {{ loading.content }} <br />
      <img :src="loadingGif" class="img img-fluid" alt="loading" />
    </CModalBody>
  </CModal>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import loadingGif from "@/assets/images/loading.gif";

export default {
  name: "Loading",
  setup() {
    const store = useStore();
    return {
      loading: computed(() => store.state.loading),
      loadingGif,
    };
  },
};
</script>

<style lang="scss" scoped></style>
