import ApiMss from "@/api/ApiMss";
import { useCookies } from "vue3-cookies";
import { formattedDate } from "@/helper/util.js";

const agendaService = {
  getAllCitas,
  generarCita,
  actualizaCita,
  generarCitaOportunidad,
  generarBloqueoHora,
  getBlockedHours,
  envioCorreoAgenda,
};

async function actualizaCita(body) {
  return await ApiMss.put("/V1/appointment", body);
}

async function getAllCitas(body) {
  const cookies = useCookies();
  const user = cookies.cookies.get("user");
  let colaborador = user.idColaborator;
  if (body.colaborador.length > 0) {
    colaborador = body.colaborador;
  }
  let accountRut = "";
  if (body.rutCliente) {
    accountRut = `&accountRut=${body.rutCliente}`;
  }
  return await ApiMss.get(
    `/V1/calendaruserweek?users=${colaborador}&startDate=${body.startDate}&endDate=${body.endDate}${accountRut}`
  );
}

async function getBlockedHours(body) {
  const cookies = useCookies();
  const user = cookies.cookies.get("user");
  let colaborador = user.idColaborator;
  if (body.user) {
    colaborador = body.user;
  }
  return await ApiMss.get(
    `/V1/calendaruserday?user=${colaborador}&date=${body.date}&types=${body.types}`
  );
}

async function generarCita(cita) {
  const cookies = useCookies();
  const user = cookies.cookies.get("user");
  const branchSelected = cookies.cookies.get("branchSelected");
  let body = {
    type: cita.type,
    nameType: cita.nameType,
    branch: branchSelected.branch._id,
    nameBranch: branchSelected.branch.fantasyName,
    dealer: branchSelected.branch.dealer._id,
    nameDealer: branchSelected.branch.dealer.alias,
    user: user.idColaborator,
    nameUser: user.firstName + " " + user.lastName,
    rutUser: null,
    scheduleUser: "",
    nameScheduleUser: "",
    brand: branchSelected.branch.dealer.brand._id,
    nameBrand: branchSelected.branch.dealer.brand.name,
    model: null,
    nameModel: null,
    version: null,
    nameVersion: null,
    account: null,
    accountFirstName: null,
    accountLastName: null,
    accountRut: cita.rut ?? user.rut,
    accountEmail: null,
    accountPhone: null,
    OppId: null,
    urlOppId: null,
    lastContact: null,
    subject: cita.rut ?? user.rut,
    date: cita.date,
    start: cita.start,
    end: cita.end,
    comments: cita.rut
      ? "Se ha generado una agenda de tipo " +
          cita.nameType +
          ", para rut " +
          cita.rut ?? user.rut
      : cita.comment ?? "Se ha generado un recordatorio",
    quoteId: null,
    timeNotification: cita.timeNotification ?? null,
    typeNotification: cita.typeNotification ?? null,
    subTypeNotification: cita.subTypeNotification ?? null,
    notification: cita.notification ?? null,
    emailInternal: cita.emailInternal ?? null,
    emailExternal: cita.emailExternal ?? null,
  };
  return await ApiMss.post("/V1/appointment", body);
}

async function generarCitaOportunidad(cita, oportunidad, isQuote) {
  const cookies = useCookies();
  const user = cookies.cookies.get("user");
  const branchSelected = cookies.cookies.get("branchSelected");
  let body = {
    type: cita.type,
    nameType: cita.nameType,
    branch: branchSelected.branch._id, //
    nameBranch: branchSelected.branch.fantasyName,
    dealer: branchSelected.branch.dealer._id,
    nameDealer: branchSelected.branch.dealer.alias,
    user: user.idColaborator,
    nameUser: user.firstName + " " + user.lastName,
    rutUser: oportunidad.Account.Rut,
    scheduleUser: "",
    nameScheduleUser: "",

    brand: branchSelected.branch.dealer.brand._id,
    nameBrand: branchSelected.branch.dealer.brand.name,
    model: null,
    nameModel: null,
    version: null,
    nameVersion: null,

    account: oportunidad.Account.AccountId,
    accountFirstName: oportunidad.Account.Nombres,
    accountLastName: oportunidad.Account.Apellidos,
    accountRut: oportunidad.Account.Rut,
    accountEmail: oportunidad.Account.CorreoElectronico,
    accountPhone: oportunidad.Account.TelefonoCelular,

    OppId: oportunidad.OpportunityId,
    urlOppId: "gestionLead/oportunidad/" + oportunidad.OpportunityId,
    lastContact: "",
    subject:
      oportunidad.Account.Nombres +
      " " +
      oportunidad.Account.Apellidos +
      " - " +
      oportunidad.Account.Rut,
    date: cita.date,
    start: cita.start,
    end: cita.end,
    comments: cita.rut
      ? "Se ha generado una agenda de tipo " +
          cita.nameType +
          ", para rut " +
          cita.rut ?? user.rut
      : cita.comment ?? "Se ha generado un recordatorio",
    quoteId: null,
    timeNotification: cita.timeNotification ?? null,
    typeNotification: cita.typeNotification ?? null,
    subTypeNotification: cita.subTypeNotification ?? null,
    notification: cita.notification ?? null,
    emailInternal: cita.emailInternal ?? null,
    emailExternal: cita.emailExternal ?? null,
  };

  return await ApiMss.post("/V1/appointment", body);
}

async function generarBloqueoHora(cita) {
  let body = {
    type: cita.type,
    nameType: cita.nameType,
    user: cita.user,
    nameUser: cita.nameUser,
    date: cita.date,
    start: cita.start,
    end: cita.end,
    comment: cita.comment,
  };
  return await ApiMss.post("/V1/blockedhours", body);
}

async function envioCorreoAgenda(tag, idOportunidad) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  let body = {
    opportunityId: idOportunidad,
    type: 1,
    subType: 1,
    emailExternal: true,
    tag: tag.type,
    nameTag: tag.nameType,
    branch: branchSelected.branch.fantasyName,
    date: formattedDate(tag.date, "DD-MM-yyyy"),
    timeStart: tag.start,
    timeEnd: tag.end,
  };
  return await ApiMss.post(`/mss-core/v1/notification/send`, body);
}

export default agendaService;
