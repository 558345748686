import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

var firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROYECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MENSSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_API_ID,
  //measurementId: process.env.VUE_APP_MEASUREMENT_ID
};

const firebaseApp = initializeApp(firebaseConfig);

export default getMessaging(firebaseApp);
