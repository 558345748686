import ApiPortal from "@/api/ApiPortal";
import ApiMss from "@/api/ApiMss";

// servicios expuestos
const roleService = {
  getPositionRole,
  getModulesByApplication,
  checkEmaiUserFederated,
};

async function getPositionRole(id) {
  let application = process.env.VUE_APP_APPLICATION;
  return await ApiPortal.get(
    `portal/user/get/roles?user=${id}&application=${application}`
  );
}

async function getModulesByApplication() {
  let application = process.env.VUE_APP_APPLICATION;
  return await ApiPortal.get(
    `portal/module/list/with/references?application=${application}`
  );
}

async function checkEmaiUserFederated(email) {
  return await ApiMss.get(
    `mss-core/v1/authentication/check/email?email=${email}`
  );
}

export default roleService;
