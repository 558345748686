import ApiMss from "@/api/ApiMss";
import { useCookies } from "vue3-cookies";

// servicios expuestos
const comentarioService = {
  upsertComentario,
  getComentarios,
};

async function getComentarios(idOportunidad) {
  return await ApiMss.get(
    `/mss-core/v1/comments/list?OpportunityId=${idOportunidad}`
  );
}

async function upsertComentario(mensaje, idOportunidad) {
  const cookies = useCookies();
  const user = cookies.cookies.get("user");
  let body = {
    OpportunityId: idOportunidad,
    RutEjecutivo: user.rut,
    Annotation: {
      Subject: mensaje.Subject,
      Notetext: mensaje.Notetext,
    },
  };

  return await ApiMss.post(`/mss-crm/v1/CreateComment`, body);
}

export default comentarioService;
