import { h, createApp, onMounted } from "vue";
import singleSpaVue from "single-spa-vue";
import firebaseMessaging from "./firebase";
import VueCookies from "vue3-cookies";
import CoreuiVue from "@coreui/vue";
import VCalendar from "v-calendar";
import "aos/dist/aos.css";
import AOS from "aos";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import VueNumberFormat from "@coders-tm/vue-number-format";
import { Vue3ProgressPlugin } from "@marcoschulte/vue3-progress";
import { createStore } from "vuex";
import { VueClipboard } from "@soerenmartius/vue3-clipboard";
import App from "./App.vue";
import router from "./router";
import storeDefinition from "./store";

const store = createStore(storeDefinition);

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render() {
      return h(App, {});
    },
  },
  handleInstance(app) {
    app.config.globalProperties.$messaging = firebaseMessaging;
    app.config.globalProperties.$formatterMoney = new Intl.NumberFormat(
      "es-CL",
      {
        style: "currency",
        currency: "CLP",
      }
    );
    app
      .use(store)
      .use(router)
      .use(CoreuiVue)
      .use(VCalendar, {})
      .use(VueCookies)
      .use(Vue3ProgressPlugin)
      .use(VueClipboard)
      .use(VueNumberFormat, {
        decimal: ",",
        separator: ".",
        prefix: "$",
        precision: 2,
        min: 0,
        max: 999999999999999,
      })
      .component(VueQrcode.name, VueQrcode);
  },
});

// if (process.env.NODE_ENV !== "development") {
//   console.log = function () {};
//   console.info = function () {};
//   console.warn = function () {};
//   console.error = function () {};
//   console.debug = function () {};
// }

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;

AOS.init();
