import ApiMss from "@/api/ApiMss";
import { objectToQueryString } from "@/helper/util";
import { useCookies } from "vue3-cookies";

const distribuidorVentaService = {
  getDistribuidorVenta,
  upsertDistribuidorVenta,
  getDistribuidorVentaDealer,
};

async function getDistribuidorVentaDealer(filter) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  filter.dealer = branchSelected.branch.dealer._id;
  // filter.branch = branchSelected.branch._id;

  const queryString = objectToQueryString(filter);
  return await ApiMss.get(`/mss-core/v1/dealerSale/list?${queryString}`);
}

async function getDistribuidorVenta(filter) {
  const queryString = objectToQueryString(filter);
  return await ApiMss.get(`/mss-core/v1/dealerSale/list?${queryString}`);
}

async function upsertDistribuidorVenta(body) {
  return await ApiMss.post(`/mss-core/v1/dealerSale/upsert`, body);
}

export default distribuidorVentaService;
