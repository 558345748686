import ApiMss from "@/api/ApiMss";

// servicios expuestos
const tipoService = {
  getTipos,
  upsertTipos,
};

async function getTipos() {
  return await ApiMss.get(`/mss-core/v1/types/list`);
}

async function upsertTipos(body) {
  return await ApiMss.post(`/mss-core/v1/types/upsert`, body);
}

export default tipoService;
