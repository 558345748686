import getKeyVaultSecret from '@/helper/config.js';
import axios from 'axios';
import { ref } from 'vue';
import {useProgress} from '@marcoschulte/vue3-progress';

const progresses = ref([]);

class ApiAuthenticate {
    constructor() {
        const client = getKeyVaultSecret();
        if (!client) {
            const { VUE_APP_API_HOST_PORTAL, VUE_APP_API_KEY_PORTAL, VUE_APP_API_KEY_VALUE_PORTAL } = process.env;
            this.baseURL = VUE_APP_API_HOST_PORTAL;
            this.headers = {
                [VUE_APP_API_KEY_PORTAL]: VUE_APP_API_KEY_VALUE_PORTAL
            };
            if (process.env.NODE_ENV == "development") console.info('INIT: ApiAuthenticate from env');
        } else {
            this.baseURL = client.getSecret('API_HOST_AUTHENTICATE');
            this.headers = {
                [client.getSecret('API_KEY_AUTHENTICATE')]: client.getSecret('API_KEY_VALUE_AUTHENTICATE')
            };
            if (process.env.NODE_ENV == "development") console.info('INIT: ApiAuthenticate from Vault');
        }

        this.instance = axios.create({
            baseURL: this.baseURL
        });
    
        this.instance.interceptors.request.use((config) => {
            if (document.getElementsByClassName("vue3-progress-bar")[0]) 
                document.getElementsByClassName("vue3-progress-bar")[0].style = "background-color: #42b983 !default;";    
            progresses.value.pop()?.finish();
            progresses.value.push(useProgress().start());
            config.headers = {
                ...config.headers,
                ...this.headers
            };
            return config;
        });

        this.instance.interceptors.response.use(
            function (response) {
                progresses.value.pop()?.finish();
                return response;
            },
            function (error) {
                if (document.getElementsByClassName("vue3-progress-bar")[0]) 
                    document.getElementsByClassName("vue3-progress-bar")[0].style = "background-color: red";
                progresses.value.pop()?.finish();
                return Promise.reject(error);
            }
        );
    }
    getInstance() {
        return this.instance;
    }
}
export default new ApiAuthenticate().getInstance();