import ApiMss from "@/api/ApiMss";
import { useCookies } from "vue3-cookies";

// servicios expuestos
const clienteService = {
  updateCliente,
};

async function updateCliente(body) {
  const cookies = useCookies();
  const user = cookies.cookies.get("user");
  body.RutEjecutivo = user.rut;
  return await ApiMss.post(`/mss-crm/v1/UpdateContact`, body);
}
export default clienteService;
