import ApiMss from "@/api/ApiMss";
import { useCookies } from "vue3-cookies";
import { formattedDate } from "@/helper/util";
import { objectToQueryString } from "@/helper/util";

const reporteService = {
  getDealerByPerfil,
  getInidicadores,
  getIndicadoresAgenda,
  getExtras,
  getReportFases,
  getWeb,
  getIndicadoresWhatsapp,
  getIndicadoresReporte,
  getIndicatorsReport,
  getReportingAppointments,
};

async function getDealerByPerfil(active = true) {
  const cookies = useCookies();
  const user = cookies.cookies.get("user");
  const branchSelected = cookies.cookies.get("branchSelected");
  let queryString = "active=" + active;

  if (user.position.toLowerCase().includes("vendedor")) {
    queryString += "&_id=" + branchSelected.branch.dealer._id;
  }

  if (user.position.toLowerCase().includes("jefe_sucursal")) {
    queryString += "&_id=" + branchSelected.branch.dealer._id;
  }

  if (user.position.toLowerCase().includes("jefe_marca")) {
    queryString += "&brand=" + branchSelected.branch.dealer.brand._id;
  }

  return await ApiMss.get(
    `/mss-v-maestros/v1/dealer/list/select?${queryString}`
  );
}

async function getInidicadores(mesAnterior = false) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  const user = cookies.cookies.get("user");

  var fecha = new Date();
  if (mesAnterior)
    fecha = new Date(fecha.getFullYear(), fecha.getMonth() - 1, 1);

  var primerDia = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
  var ultimoDia = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);

  let queryString = "";
  if (user.position.toLowerCase().includes("vendedor")) {
    queryString = "&RutVendedor=" + user.rut;
  }

  return await ApiMss.get(
    `/mss-core/v1/indicators/list?StartDate=${formattedDate(
      primerDia,
      "YYYY-MM-DD"
    )}&EndDate=${formattedDate(ultimoDia, "YYYY-MM-DD")}&Role=${
      user.position
    }&Dealer=${branchSelected.branch.dealer._id}&Branch=${
      branchSelected.branch._id
    }${queryString}`
  );
}

async function getIndicadoresReporte(branchFiltro, mesAnterior = false) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  const user = cookies.cookies.get("user");

  var fecha = new Date();
  if (mesAnterior)
    fecha = new Date(fecha.getFullYear(), fecha.getMonth() - 1, 1);

  var primerDia = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
  var ultimoDia = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);

  let queryString = "";
  if (user.position.toLowerCase().includes("vendedor")) {
    queryString = "&RutVendedor=" + user.rut;
  }

  return await ApiMss.get(
    `/mss-core/v1/indicators/list?StartDate=${formattedDate(
      primerDia,
      "YYYY-MM-DD"
    )}&EndDate=${formattedDate(ultimoDia, "YYYY-MM-DD")}&Role=${
      user.position
    }&Dealer=${
      branchSelected.branch.dealer._id
    }&Branch=${branchFiltro}${queryString}`
  );
}

async function getExtras(
  dealerFiltro,
  branchFiltro,
  selectedColaboradorFiltro
) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  const user = cookies.cookies.get("user");

  let selectedColaborador = "";
  let branch = "";
  let dealer = "";

  if (user.position.toLowerCase().includes("vendedor")) {
    selectedColaborador = user.rut;
    branch = branchFiltro;
  } else if (user.position.toLowerCase().includes("jefe_sucursal")) {
    branch = branchFiltro;
    selectedColaborador = selectedColaboradorFiltro;
  } else if (user.position.toLowerCase().includes("jefe_marca")) {
    dealer = dealerFiltro;
    branch = branchFiltro;
    selectedColaborador = selectedColaboradorFiltro;
  } else {
    selectedColaborador = selectedColaboradorFiltro;
    branch = branchFiltro;
  }

  return await ApiMss.get(
    `/mss-indicators/v1/extras?dealers=${dealer}&branches=${branch}&ruts=${selectedColaborador}`
  );
}

async function getReportFases(
  dealerFiltro,
  branchFiltro,
  selectedColaboradorFiltro
) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  const user = cookies.cookies.get("user");

  let selectedColaborador = "";
  let branch = "";
  let dealer = "";

  if (user.position.toLowerCase().includes("vendedor")) {
    selectedColaborador = user.rut;
    branch = branchFiltro;
  } else if (user.position.toLowerCase().includes("jefe_sucursal")) {
    branch = branchFiltro;
    selectedColaborador = selectedColaboradorFiltro;
  } else if (user.position.toLowerCase().includes("jefe_marca")) {
    dealer = dealerFiltro;
    branch = branchFiltro;
    selectedColaborador = selectedColaboradorFiltro;
  } else {
    selectedColaborador = selectedColaboradorFiltro;
    branch = branchFiltro;
  }

  return await ApiMss.get(
    `/mss-indicators/v1/indicators/phases?dealers=${dealer}&branches=${branch}&ruts=${selectedColaborador}`
  );
}

async function getWeb(dealerFiltro, branchFiltro, selectedColaboradorFiltro) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  const user = cookies.cookies.get("user");

  let selectedColaborador = "";
  let branch = "";
  let dealer = "";

  if (user.position.toLowerCase().includes("vendedor")) {
    selectedColaborador = user.rut;
    branch = branchFiltro;
  } else if (user.position.toLowerCase().includes("jefe_sucursal")) {
    branch = branchFiltro;
    selectedColaborador = selectedColaboradorFiltro;
  } else if (user.position.toLowerCase().includes("jefe_marca")) {
    dealer = dealerFiltro;
    branch = branchFiltro;
    selectedColaborador = selectedColaboradorFiltro;
  } else {
    selectedColaborador = selectedColaboradorFiltro;
    branch = branchFiltro;
  }

  return await ApiMss.get(
    `/mss-indicators/v1/web?dealers=${dealer}&branches=${branch}&ruts=${selectedColaborador}`
  );
}

async function getIndicadoresAgenda(
  dealers,
  branches,
  users,
  startDate,
  endDate,
  mesAnterior = false
) {
  const cookies = useCookies();
  const user = cookies.cookies.get("user");

  if (mesAnterior)
    fecha = new Date(fecha.getFullYear(), fecha.getMonth() - 1, 1);

  if (user.position.toLowerCase().includes("vendedor"))
    users = user.idColaborator;

  return await ApiMss.get(
    `/mss-core/v1/ReportingAppointments?dealers=${dealers}&branches=${branches}&users=${users}&startDate=${formattedDate(
      startDate
    )}&endDate=${formattedDate(endDate)}`
  );
}

async function getIndicadoresWhatsapp(
  dealers,
  branches,
  users,
  startDate,
  endDate,
  mesAnterior = false
) {
  const cookies = useCookies();
  const user = cookies.cookies.get("user");
  let company = "kia";

  if (user.position.toLowerCase().includes("vendedor")) {
    users = [];
    users.push(user.idColaborator);
  }

  let body = {
    //company: company,
    //dateFrom: "01/2024",
    company: company,
    dateFrom: formattedDate(startDate, "MM/YYYY"),
    dealerId: dealers,
    branchId: branches,
    collaboratorId: users,
  };
  return await ApiMss.post(`/mss-core/v1/metrics/getMetricsDealers`, body);
}

async function getIndicatorsReport(filter) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  const user = cookies.cookies.get("user");
  delete filter.dateSelected;
  filter.roleUser = user.roleId;
  if (!filter.dealer) filter.dealer = branchSelected.branch.dealer._id;
  if (!filter.rutVendedor) filter.rutVendedor = user.rut;
  let queryString = objectToQueryString(filter);

  return await ApiMss.get(
    `/mss-indicators/v1/indicators/phases?${queryString}`
  );
}

async function getReportingAppointments(filter) {
  const queryString = objectToQueryString(filter);
  return await ApiMss.get(`V1/ReportingAppointmentsv2?${queryString}`);
}
export default reporteService;
