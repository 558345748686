import ApiPortal from "@/api/ApiPortal";
import ApiMss from "@/api/ApiMss";
import { useCookies } from "vue3-cookies";
import { objectToQueryString } from "@/helper/util";

// servicios expuestos
const notificacionService = {
  updateTokenDevice,
  getAllByUsuario,
};

async function updateTokenDevice(token, user) {
  let body = {
    user: user,
    deviceToken: token,
  };
  return await ApiPortal.put(`portal/user/set/token/device`, body);
}

async function getAllByUsuario(filter) {
  const cookies = useCookies();
  const user = cookies.cookies.get("user");
  const queryString = objectToQueryString({
    rut: user.rut,
    ...filter,
  });
  return await ApiMss.get(`/mss-core/v1/notifications/list?${queryString}`);
}

export default notificacionService;
