<template>
  <div v-if="visible">
    <img
      @click="toggleChatBox"
      class="whatsapp-logo shadow"
      :src="whatsapp"
      alt="Abrir Chat en WhatsApp"
    />
    <div :class="{ 'd-none': !showChatBox }" class="chat-box">
      <button
        title="Ver Chats"
        @click="showConversations"
        :class="
          conversationsVisible
            ? 'btn btn-primary floating-btn'
            : 'btn btn-white floating-btn'
        "
      >
        <MessageSquareText
          :absoluteStrokeWidth="true"
          :strokeWidth="2"
          size="24"
        ></MessageSquareText>
      </button>
      <div
        v-if="conversationsVisible"
        class="conversations-list overflow-scroll"
      >
        <div class="chats-heading pt-2 ps-2">Chats:</div>
        <div v-if="!conversations.length">
          <CAlert color="info"
            ><MessageCircleOff
              :absoluteStrokeWidth="true"
              :strokeWidth="2"
              size="50"
            />&nbsp;Ups!&nbsp;No hemos encontrado conversaciones en las últ. 24
            hrs.</CAlert
          >
        </div>
        <div
          v-for="conversation in conversations"
          :key="conversation.opportunityId"
          @click="selectConversation(conversation)"
          class="conversation-item"
        >
          <div class="conversation-content">
            <div class="conversation-text">
              <strong class="d-block text-center mb-1 chats-heading"
                ><MessageCircleMore
                  :absoluteStrokeWidth="true"
                  :strokeWidth="2"
                  size="24"
                />
                {{
                  conversation?.client_firstname
                    ? conversation.client_firstname +
                      " " +
                      conversation.client_lastname
                    : conversation.client_rut +
                      "- +" +
                      conversation.client_phone
                }}
              </strong>
              <span class="small-text d-block text-mute ms-4 mt-2">{{
                formattedDate(
                  conversation.last_message_data,
                  "HH:mm [hrs] - ddd DD, MMMM"
                )
              }}</span>
            </div>
          </div>
          <div class="text-end">
            <NotificationCounter
              :type="'danger'"
              :counter="conversation.unreadMessages"
              class="me-3"
            />
          </div>
        </div>
      </div>
      <iframe
        :class="{ 'd-none': conversationsVisible }"
        id="frameChat"
        :src="chatUrl"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, computed, watch } from "vue";
import whatsapp from "@/assets/images/whatsapp.png";
import { useStore } from "vuex";
import {
  MessageCircleMore,
  MessageCircle,
  MessageSquareText,
  MessageCircleOff,
} from "lucide-vue-next";
import { formattedDate } from "@/helper/util";
import { NotificationCounter } from "@/components/NotificationCounter";
import { whatsappServices } from "/cl.bms.indumotora.services/services/index.js";

export default {
  components: {
    MessageCircleMore,
    NotificationCounter,
    MessageCircle,
    MessageSquareText,
    MessageCircleOff,
  },
  setup() {
    const store = useStore();
    const state = reactive({
      showChatBox: computed(() => store.state.showChatBox),
      visible: computed(() => store.state.visibleWhatsapp),
      conversationsVisible: false,
      conversations: [],
      chatUrl: computed(
        () =>
          `${window.location.origin}/chat?PHONE=${encodeURIComponent(
            store.state.payloadWhatsapp.phone
          )}&RUT=${encodeURIComponent(
            store.state.payloadWhatsapp.rut
          )}&OPPORTUNITYID=${encodeURIComponent(
            store.state.payloadWhatsapp.opportunityId
          )}&FIRSTNAME=${encodeURIComponent(
            store.state.payloadWhatsapp.firstName
          )}&LASTNAME=${encodeURIComponent(
            store.state.payloadWhatsapp.lastName
          )}`
      ),
    });

    const showConversations = async () => {
      await loadChatHistory();
      state.conversationsVisible = !state.conversationsVisible;
    };

    const selectConversation = (conversation) => {
      store.dispatch("payloadWhatsapp", {
        opportunityId: conversation.opportunityId,
        phone: conversation.client_phone,
        rut: conversation.client_rut,
        firstName: conversation?.client_firstname || "",
        lastName: conversation?.client_lastname || "",
      });
      state.conversationsVisible = false;
    };

    watch(
      () => state.chatUrl,
      () => {
        const iframe = document.getElementById("frameChat");
        if (iframe) iframe.src = state.chatUrl;
      }
    );

    const toggleChatBox = async () => {
      await loadChatHistory();
      store.dispatch("showChatBox", !state.showChatBox);
    };

    const loadChatHistory = async () => {
      whatsappServices
        .getUltimasConversaciones()
        .then((response) => {
          if (response?.status != 200) return;
          state.conversations = response.data.description.conversaciones;
        })
        .catch((err) => {
          console.log(err);
        });
    };

    onMounted(async () => {
      window.addEventListener("message", (event) => {
        if (event.data.type === "conversationClosed") {
          store.state.visibleWhatsapp = false;
        }
      });
      await loadChatHistory();
      const iframe = document.getElementById("frameChat");
      if (iframe) {
        iframe.addEventListener("load", async () => {
          if (iframe.contentWindow) {
            console.log("El iframe se cargó correctamente");
          } else {
            console.error("Error al cargar el iframe");
          }
        });
      }
    });

    return {
      ...toRefs(state),
      whatsapp,
      toggleChatBox,
      showConversations,
      selectConversation,
      formattedDate,
    };
  },
};
</script>

<style lang="scss" scoped>
.badge.rounded-circle-danger {
  border-radius: 1rem !important;
  background: var(--error-50, #fef3f2);
  color: #c9271c;
}

.chats-heading {
  font-weight: bold;
  margin-bottom: 10px;
}

.small-text {
  font-size: 0.8em;
  color: #777;
  text-align: center; /* Alineación del texto centrado */
}

.conversations-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.conversation-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s;
  margin-bottom: 10px;
}

.conversation-item:hover {
  background-color: #f0f0f0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.conversation-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.conversation-text {
  flex-grow: 1;
  text-align: center;
}

.conversation-date {
  font-size: 0.8em;
  color: #777;
  margin-left: 10px;
}

html {
  overflow: hidden;
}

/* Estilo base para pantallas grandes (lg) y medianas (md) */
.chat-box {
  position: fixed;
  right: 4.5em;
  bottom: 2.5em;
  width: 30%;
  height: 91%;
  background-color: #fff;
  border-radius: 0.5em;
  box-shadow: 0 0 0.5333em rgba(0, 0, 0, 0.2);
  z-index: 999;
  overflow: hidden;
  transition: 0.3s;
}

iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.whatsapp-logo {
  position: fixed;
  bottom: 1em;
  right: 3em;
  z-index: 1000;
  cursor: pointer;
  width: 3em; /* Ajusta el ancho según tus necesidades */
  height: 3em; /* Ajusta la altura según tus necesidades */
  border-radius: 1em;
  transition: transform 0.2s; /* Agrega una transición para el efecto hover */
}

.whatsapp-logo:hover {
  transform: scale(1.1); /* Escala el logo al 110% en el efecto hover */
}

.floating-btn {
  position: fixed;
  right: 0.5em;
  z-index: 1001;
}
</style>
