import ApiTwilio from "@/api/ApiTwilio";
import ApiMss from "@/api/ApiMss";
import { useCookies } from "vue3-cookies";

const twilioService = {
  initCall,
  getCallOptionTypes,
};

async function initCall(body) {
  const cookies = useCookies();
  const user = cookies.cookies.get("user");
  const branchSelected = cookies.cookies.get("branchSelected");
  body.collaborator = user.idColaborator;
  body.branch = branchSelected.branch._id;
  return await ApiTwilio.post(`/twilio/create/call`, body);
}

async function getCallOptionTypes(state) {
  if (state == null || state == undefined) {
    return await ApiMss.get(`/mss-core/v1/callOptionTypes/list`);
  } else {
    return await ApiMss.get(`/mss-core/v1/callOptionTypes/list?state=` + state);
  }
}

export default twilioService;
