import ApiMss from "@/api/ApiMss";

// servicios expuestos
const slaService = {
  getSla,
  getSlaTypes,
  getStatus,
  upsertSla,
};

async function getSla() {
  return await ApiMss.get(`/mss-sla/v1/config/list`);
}

async function getSlaTypes() {
  return await ApiMss.get(`/mss-sla/v1/types/list`);
}

async function getStatus() {
  return await ApiMss.get(`/mss-sla/v1/status/list`);
}

async function upsertSla(body) {
  return await ApiMss.post(`/mss-sla/v1/config/upsert`, body);
}

export default slaService;
