<template>
  <CRow>
    <CCol>
      <CInputGroup :class="'input-search-' + background">
        <CInputGroupText>
          <Search />
        </CInputGroupText>
        <CFormInput
          id="inputSearch"
          v-model="toSearch"
          @keyup.enter="onPressEnter"
          @blur="onPressEnter"
          type="text"
          :placeholder="placeholder ?? 'Buscar'"
          :maxlength="maxlength"
          :minlength="minlength"
          class="input-style"
        />
      </CInputGroup>
    </CCol>
  </CRow>
</template>

<script>
import { reactive, toRefs, toRef, watchEffect } from "vue";
import { Search } from "lucide-vue-next";
import { formatRut, formatCleanRut, Fn } from "@/helper/util";

export default {
  name: "InputSearch",
  components: {
    Search,
  },
  props: {
    background: {
      type: String,
      required: true,
    },
    maxlength: 20,
    minlength: 1,
    format: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
    }
  },
  setup(props, context) {
    const state = reactive({
      toSearch: "",
      ...toRefs(props),
    });

    watchEffect(() => {
      if (props?.format == "rut") {
        if (state.toSearch) {
          state.toSearch = formatRut(state.toSearch);
        }
      }
    });

    const onPressEnter = () => {
      context.emit("update:toSearch", state.toSearch);
    };

    return {
      ...toRefs(state),
      onPressEnter,
    };
  },
};
</script>
