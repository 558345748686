import ApiMss from "@/api/ApiMss";
import { useCookies } from "vue3-cookies";
import { formattedDate } from "@/helper/util";
import ApiPortal from "@/api/ApiPortal";

// servicios expuestos
const emailService = {
  sendNotificationAsignaOportunidad,
  sendNotificationSinContactar,
  sendNotificationCotizacion,
  sendEmail,
};

async function sendNotificationAsignaOportunidad(
  address,
  displayName,
  NombreVendedor,
  rutVendedor,
  idOportunidad
) {
  if (!address) return { status: false, error: "debe informar email" };
  if (!displayName)
    return { status: false, error: "debe informar displayName" };
  if (!NombreVendedor)
    return { status: false, error: "debe informar nombre vendedor" };
  if (!rutVendedor)
    return { status: false, error: "debe informar rut del vendedor" };
  if (!idOportunidad)
    return { status: false, error: "debe informar id de la oportunidad" };

  let request = {
    rut: rutVendedor || null, // rut vendedor
    oppId: idOportunidad || null,
    email: {
      body: {
        application: 1,
        type: 1,
        subject: "MSS",
        recipients: {
          to: [
            {
              address: address,
              displayName: displayName,
            },
          ],
        },
        firstText: `Estimado(a) vendedor(a) ${NombreVendedor}.`,
        secondText: `Se le ha asignado la siguiente notificación: Asignación. 
                Asociada a la oportunidad ${idOportunidad}.`,
        dealerLogoBlack:
          "https://indumotorastorage.blob.core.windows.net/portal-indumotora/dealer/logo/1685377584120.png",
      },
      contentType: "application/json",
    },
    notification: {
      title: `Estimado(a) vendedor(a) ${NombreVendedor}.`,
      body: `Se le ha asignado la siguiente notificación: Asignación. 
            Asociada a la oportunidad ${idOportunidad}.`,
      type: "oportunity",
      subType: "assign",
    },
  };
  return await ApiMss.post(`/mss-core/v1/mss/notification/send`, request);
}

async function sendNotificationSinContactar(
  address,
  displayName,
  nombreCliente,
  numeroCliente,
  rutVendedor,
  idOportunidad
) {
  if (!address) return { status: false, error: "debe informar email" };
  if (!displayName)
    return { status: false, error: "debe informar displayName" };
  if (!nombreCliente)
    return { status: false, error: "debe informar nombreCliente" };
  if (!numeroCliente)
    return { status: false, error: "debe informar numeroCliente" };
  if (!rutVendedor)
    return { status: false, error: "debe informar rut del vendedor" };
  if (!idOportunidad)
    return { status: false, error: "debe informar id de la oportunidad" };

  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  const user = cookies.cookies.get("user");
  var fechaActual = new Date();
  var horaActual = fechaActual.getHours();

  // Determina si es AM o PM
  var saludo = "";
  if (horaActual >= 0 && horaActual < 12) {
    saludo = "Buenos días";
  } else {
    saludo = "Buenas tardes";
  }

  let request = {
    rut: rutVendedor || null, // rut vendedor
    oppId: idOportunidad || null,
    email: {
      body: {
        application: 1,
        type: 1,
        subject: "MSS",
        recipients: {
          to: [
            {
              address: address,
              displayName: displayName,
            },
          ],
        },
        firstText: `Estimado(a) ${nombreCliente}.`,
        secondText: `${saludo}`,
        thirdText: `Junto con saludarle le comento que no he podido ponerme en contacto con usted al télefono indicado ${numeroCliente}. 
                Si gusta puede contactarme al ${branchSelected.branch.dealer.phoneTwilio} o me escribe a ${user.mail} para darle la mejor atención a su requerimiento. 
                De todas formas intentaré comunicarme nuevamente más tarde. 
                Se despide muy atentamente,`,
        dealerLogoBlack:
          "https://indumotorastorage.blob.core.windows.net/portal-indumotora/dealer/logo/1685377584120.png",
      },
      contentType: "application/json",
    },
  };
  return await ApiMss.post(`/mss-core/v1/mss/notification/send`, request);
}

async function sendNotificationCotizacion(
  address,
  displayName,
  oportunidad,
  cliente,
  quoteId
) {
  if (!address) return { status: false, error: "debe informar email" };
  if (!displayName)
    return { status: false, error: "debe informar displayName" };
  if (!oportunidad)
    return { status: false, error: "debe informar oportunidad" };
  if (!cliente) return { status: false, error: "debe informar cliente" };
  if (!quoteId) return { status: false, error: "debe informar quoteId" };

  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");

  let quote = oportunidad.quotes.find((x) => x.QuoteId == quoteId);
  let vehiculoCotizado = quote.QuoteDetail.find(
    (x) => x.FueraCatalogo == false
  );

  let request = {
    rut: oportunidad.Colaborador.rut || null, // rut vendedor
    oppId: oportunidad.OpportunityId || null,
    email: {
      body: {
        application: 1,
        type: 1,
        subject: "MSS",
        recipients: {
          to: [
            {
              address: address,
              displayName: displayName,
            },
          ],
        },
        firstText: `Estimado(a) ${cliente.nombres}.`,
        secondText: `Gracias por su preferencia, a continuación, encontraras la comparación del tipo de producto para tu cotización: ${quote.QuoteId}`,
        thirdText: `Modelo: ${vehiculoCotizado.Modelo} ${
          vehiculoCotizado.Version
        }
                Fecha: ${formattedDate(vehiculoCotizado.ModifyOn, "DD/MM/YYYY")}
                Vendedor: ${oportunidad.Colaborador.firstName} ${
          oportunidad.Colaborador.lastName
        }
                Sucursal: ${branchSelected.branch.fantasyName}
                El detalle de la cotización lo encontrarás en el documento adjunto, al igual que la ficha de especificaciones del modelo cotizado.`,
        dealerLogoBlack:
          "https://indumotorastorage.blob.core.windows.net/portal-indumotora/dealer/logo/1685377584120.png",
      },
      contentType: "application/json",
    },
  };
  return await ApiMss.post(`/mss-core/v1/mss/notification/send`, request);
}

async function sendEmail(id, quotes) {
  const cookies = useCookies();
  const branchSelected = cookies.cookies.get("branchSelected");
  const user = cookies.cookies.get("user");
  let body = {
    opportunityId: id,
    rutVendedor: user.rut,
    type: 4,
    //subType: 5,
    //notification: false,
    //email: true,
    //emailClient: true,
    emailExternal: true,
    quotes: quotes
  }
  return await ApiPortal.post(`/mss/notification/send`, body);
}
export default emailService;
