<template>
  <div class="container-header">
    <CHeader v-if="!sidebarUnfoldable" position="sticky" class="headerbar">
      <div class="inverted-border-radius"></div>
    </CHeader>
    <CHeader v-else position="sticky" class="headerbar-none">
      <div class="inverted-border-radius-none"></div>
    </CHeader>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  name: "AppHeader",
  setup() {
    const store = useStore();
    return {
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
    };
  },
};
</script>
