import ApiMss from "@/api/ApiMss";
import { useCookies } from "vue3-cookies";

// servicios expuestos
const interaccionService = {
  getInteraccion,
  upsertInteraccion,
  getTypeInteraccion,
  checkFeedBackInteraccion,
};

async function getInteraccion(idOportunidad) {
  return await ApiMss.get(
    `/mss-core/v1/interactions/list?opportunityId=${idOportunidad}&sort=-1`
  );
}

async function checkFeedBackInteraccion(idOportunidad) {
  const cookies = useCookies();
  const user = cookies.cookies.get("user");
  return await ApiMss.get(
    `/mss-core/v1/interactions/list?opportunityId=${idOportunidad}&checkFeedback=true&collaboratorId=${user.idColaborator}`
  );
}

async function getTypeInteraccion() {
  return await ApiMss.get(`/mss-core/v1/interactions/types`);
}

async function upsertInteraccion(body) {
  return await ApiMss.post(`/mss-core/v1/interactions/upsert`, body);
}

export default interaccionService;
