import ApiPPU from "@/api/ApiPPU";

// servicios expuestos
const vppService = {
  getPpu,
};

async function getPpu(patente) {
  if (!patente) return;
  return await ApiPPU.get(`getPPU/v1/getPPU?ppu=${patente.toUpperCase()}`);
}
export default vppService;
