<template>
  <CModal alignment="center" :visible="visible" backdrop="static">
    <CModalHeader :close-button="false">
      <CModalTitle>Seleccione la sesión:</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <CContainer>
        <CRow>
          <CCol :md="4">
            <CFormLabel class="text-sm-medium-black"
              >Sucursal&nbsp;<HelpCircle
                cursor="pointer"
                v-c-tooltip="{
                  content:
                    'Puede cambiar de sesión en desde las configuraciones en cualquier momento',
                  placement: 'top',
                }"
                color="gray"
                size="18"
            /></CFormLabel>
          </CCol>
          <CCol :md="8">
            <CFormSelect
              required
              size="sm"
              class="input-style"
              v-model="branch"
            >
              <option
                class="text-md-medium"
                :key="0"
                selected=""
                disabled=""
                value=""
              >
                Seleccione una sucursal
              </option>
              <option
                class="text-md-medium"
                v-for="b in branches"
                :value="b.branch._id"
                :key="b.branch._id"
              >
                {{ b.branch.fantasyName }}
              </option>
            </CFormSelect>
          </CCol>
        </CRow>
      </CContainer>
    </CModalBody>
    <CModalFooter>
      <CButton @click="seleccionaBranch" class="btn-primary text-md-semibold"
        >Seleccionar</CButton
      >
    </CModalFooter>
  </CModal>
</template>

<script>
import { reactive, toRefs, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { HelpCircle } from "lucide-vue-next";
import checkCircle from "@/assets/icons/check-circle.svg";

import Swal from "sweetalert2";

export default {
  name: "BranchSession",
  components: {
    HelpCircle,
    checkCircle,
  },
  setup() {
    const store = useStore();
    const state = reactive({
      visible: computed(() => store.state.visibleModalSesion),
      branch: null,
      branches: computed(() => store.state.branches),
      branchSelected: computed(() => store.state.branchSelected),
    });

    const seleccionaBranch = async () => {
      if (!state.branch) {
        Swal.fire({
          icon: "warning",
          title: "Seleccione la sesión para continuar.",
          showConfirmButton: false,
        });
        return false;
      }

      let branch = state.branches.find((x) => x.branch._id == state.branch);
      store.dispatch("selectedBranch", branch);

      Swal.fire({
        html:
          '<div class="d-flex gap-5 alertmodal-body"><div class="alertmodal-container-icon-success"><img class="alertmodal-icon-success" src="' +
          checkCircle +
          '" /></div><label class="fw-bold align-self-center"> Se ha cambiado la sucursal seleccionada para su sesión.' +
          "</label></div>",
        showConfirmButton: false,
        showCloseButton: true,
        width: "42rem"
      });
      await store.dispatch("showModalSesion", false);
      return true;
    };

    return {
      ...toRefs(state),
      seleccionaBranch,
    };
  },
};
</script>

<style lang="scss" scoped></style>
