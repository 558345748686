import {
  interaccionService,
  roleService,
} from "/cl.bms.indumotora.services/services/index.js";
import { useCookies } from "vue3-cookies";
import { capitalize } from "@/helper/util";
import AzureAdB2C from "@/helper/azureAdB2C";

export default {
  state: {
    azureAdB2C: null,
    idUser: null,
    branches: [],
    branchSelected: {
      _id: null,
      branch: {
        _id: null,
        address: null,
        dealer: {
          _id: null,
          alias: null,
          IdConcesionario: null,
          phoneTwilio: null,
          phoneWhatsapp: null,
          brand: {
            _id: null,
            IdMarca: null,
            name: null,
          },
          blackLogo: {
            url: null,
            originalName: null,
            storageName: null,
            _id: null,
          },
          whiteLogo: {
            url: null,
            originalName: null,
            storageName: null,
            _id: null,
          },
        },
        IdSucursal: null,
        fantasyName: null,
      },
    },
    visibleModalSesion: false,
    loading: {
      visible: false,
      content: "",
    },
    toasts: [],
    toastsHistory: [],
    isNewToasts: false,
    payloadWhatsapp: {
      phone: null,
      rut: null,
      opportunityId: null,
      firstName: null,
      lastName: null,
    },
    showChatBox: false,
    visibleWhatsapp: false,
    sidebarVisible: "",
    sidebarUnfoldable: true,
    isMouseOver: false,
    visibleAlertModal: false,
    alertContent: {
      icon: "",
      title: "",
      content: "",
      confirmButtonText: "",
      denyButtonText: "",
    },
    selectContent: {
      icon: "",
      content: "",
      placeholder: "",
      values: [],
    },
    visibleModalEstadoContacto: false,
    visibleOtherOppModal: false,
    interactionSelected: {},
    visibleCustomerHistoryModal: false,
    visibleOtherOppModal: false,
    toSearchSidebar: "",
    filterOpportunity: {
      OpportunityId: null,
      IdSucursal: null,
      RutVendedor: null,
      idDealer: null,
      Page: 1,
      FechaInicio: null,
      FechaFin: null,
      FaseOportunidad: null,
      Sort: 0,
      ModelId: null,
      Search: null,
      Rut: null,
      SLA: false,
      IdOrigen: null,
    },
    filterApplyOpportunity: [],
  },
  getters: {
    azureAdB2C: (state) => state.azureAdB2C,
    getToastsCount: (state) => state.toasts.length,
    getToastsHistory: (state) => state.toastsHistory,
    isNewToasts: (state) => state.isNewToasts,
    showModalConsultar(state) {
      return state.showModalConsultar;
    },
    showModalAgregarPie(state) {
      return state.showModalAgregarPie;
    },
  },
  mutations: {
    setAzureAdB2C(state, instance) {
      state.azureAdB2C = instance;
    },
    setVisibleModalSesion(state, payload) {
      state.visibleModalSesion = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setBranches(state, payload) {
      //const cookies = useCookies();
      localStorage.removeItem("branches");
      localStorage.setItem("branches", JSON.stringify({ payload }));
      payload.map((x) => {
        if (x.branch.fantasyName.split("-")[1]) {
          x.branch.fantasyName =
            x.branch.dealer.brand.name +
            " - " +
            x.branch.fantasyName.split("-")[1].trim();
        }
      });
      //cookies.cookies.set("branches", JSON.stringify({ payload }));
      state.branches = payload;
    },
    setBranchSelected(state, payload) {
      const cookies = useCookies();
      state.branchSelected = state.branches.find((x) => x._id == payload._id);
      const marca = state.branchSelected?.branch?.dealer?.brand?.name
        ? state.branchSelected?.branch?.dealer?.brand?.name + "- "
        : "";
      const fantasyName = state.branchSelected.branch.fantasyName.split("-")[1]
        ? state.branchSelected.branch.fantasyName.split("-")[1].trim()
        : state.branchSelected.branch.fantasyName;
      state.branchSelected.branch.fantasyName = marca + fantasyName;
      cookies.cookies.set(
        "branchSelected",
        JSON.stringify(state.branchSelected)
      );
      state.filterOpportunity.IdSucursal = state.branchSelected.branch._id;
      const root = document.documentElement;
      root.style.setProperty(
        "--primary-midninght",
        state.branchSelected.branch.dealer.brand.colors.primary || "#05141f"
      );
      root.style.setProperty(
        "--primary-greenlight",
        state.branchSelected.branch.dealer.brand.colors.secondary || "#9de4dd"
      );
      root.style.setProperty(
        "--primary-greenlight-nav",
        state.branchSelected.branch.dealer.brand.colors.fourth || "#57ffdc"
      );
    },
    setToasts(state, payload) {
      state.toasts.push(payload);
      state.isNewToasts = true;
    },
    updateIsNewToast(state, payload) {
      state.isNewToasts = payload;
    },
    setToastsHistory(state, toastsHistory) {
      state.toastsHistory = toastsHistory;
    },
    resetToast(state) {
      state.toasts = [];
    },
    setPayloadWhatsapp(state, payload) {
      state.payloadWhatsapp = payload;
    },
    toggleVisibleShowChatBox(state, payload) {
      state.showChatBox = payload;
    },
    toggleVisibleWhatsapp(state, payload) {
      state.visibleWhatsapp = payload;
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible;
    },
    toggleIdUser(state) {
      state.idUser = state.idUser;
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable;
    },
    toggleIsMouseOver(state, payload) {
      state.isMouseOver = payload;
    },
    resetToggleIsMouseOver(state) {
      state.isMouseOver = false;
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value;
    },
    toggleAlert(state, payload) {
      state.visibleAlertModal = true;
      state.alertContent = payload;
    },
    resetAlert(state) {
      state.visibleAlertModal = false;
      state.alertContent = {};
    },
    toggleSelectModal(state, payload) {
      state.visibleModalSelect = true;
      state.selectModalContent = payload;
    },
    resetSelectModal(state) {
      state.visibleModalSelect = false;
      state.selectModalContent = {};
    },
    toggleButtonModal(state, payload) {
      state.visibleModalButton = true;
      state.buttonModalContent = payload;
    },
    resetButtonModal(state) {
      state.visibleModalButton = false;
      state.buttonModalContent = {};
    },
    toggleModalEstadoContacto(state, payload) {
      state.visibleModalEstadoContacto = payload;
    },
    toggleModalEstado(state, payload) {
      state.visibleModalEstado = payload;
    },
    setInteractionSelected(state, payload) {
      state.interactionSelected = payload;
    },
    toggleCustomerHistoryModal(state, payload) {
      state.visibleCustomerHistoryModal = true;
      state.customerHistoryContent = payload;
    },
    toggleOtheOpp(state, payload) {
      state.visibleOtherOppModal = payload;
    },
    resetCustomerHistoryModal(state) {
      state.visibleCustomerHistoryModal = false;
      state.customerHistoryContent = {};
    },
    updateToggleSidebarSearch(state, payload) {
      state.toSearchSidebar = payload.value;
    },
    updatetoggleIdUser(state, payload) {
      state.idUser = payload.value;
    },
    setFilterOpportunity(state, payload) {
      state.filterOpportunity = payload;
    },
    setFilterApplyOpportunity(state, payload) {
      state.filterApplyOpportunity = payload;
    },
  },
  actions: {
    initializeAzureAdB2C({ commit }, policy) {
      const instance = new AzureAdB2C(policy);
      commit("setAzureAdB2C", instance);
    },
    async refreshIdToken({ commit, state }) {
      if (!state.azureAdB2C) console.error("Error azureAdB2C is null:", error);
      try {
        await state.azureAdB2C.refreshIdToken();
      } catch (error) {
        console.error("Error during refreshIdToken:", error);
      }
    },
    async getTokenUsingAuthorizationCode({ commit, state }, code) {
      if (!state.azureAdB2C) console.error("Error azureAdB2C is null:", error);
      try {
        await state.azureAdB2C.getTokenUsingAuthorizationCode(code);
      } catch (error) {
        console.error("Error during token retrieval:", error);
      }
    },
    async logout() {
      const cookies = useCookies();
      cookies.cookies.remove("token");
      cookies.cookies.remove("user");
      cookies.cookies.remove("branchSelected");
      //cookies.cookies.remove("branches");
      localStorage.removeItem("branches");
      localStorage.removeItem("role");
    },
    async loginUser(context, id) {
      try {
        const response = await roleService.getPositionRole(id);
        if (response.status === 200) {
          let rolesUser = response.data.data;
          console.log("token: " + response);
          let user = {
            id: rolesUser._id,
            idColaborator: rolesUser.collaborator._id,
            mail: rolesUser.email,
            rut: rolesUser?.rut,
            firstName: capitalize(rolesUser.firstName),
            lastName: capitalize(rolesUser.lastName),
            position: rolesUser.role.description,
            roleId: rolesUser.role._id,
          };

          await context.dispatch(
            "initBranches",
            rolesUser.collaborator.branches
          );

          if (rolesUser.collaborator.branches.length === 1) {
            await context.dispatch(
              "selectedBranch",
              rolesUser.collaborator.branches[0]
            );
          } else {
            await context.dispatch("showModalSesion", true);
          }
          const cookies = useCookies();
          cookies.cookies.set("user", JSON.stringify(user));
          localStorage.setItem("role", JSON.stringify(rolesUser.role));
          return true;
        } else {
          console.error("Unauthorized user");
          return false;
        }
      } catch (err) {
        console.error("Error getPositionRole ", err);
        return false;
      }
    },
    async loadStoredState(context) {
      const cookies = useCookies();
      if (
        localStorage.getItem("branches") != null &&
        localStorage.getItem("branches") !== "undefined"
      ) {
        await context.commit(
          "setBranches",
          JSON.parse(localStorage.getItem("branches")).payload
        );
      }
      if (
        cookies.cookies.get("branchSelected") != null &&
        cookies.cookies.get("branchSelected") !== "undefined"
      ) {
        await context.commit(
          "setBranchSelected",
          cookies.cookies.get("branchSelected")
        );
      }
    },
    async showModalSesion(context, payload) {
      context.commit("setVisibleModalSesion", payload);
    },
    async initBranches(context, payload) {
      context.commit("setBranches", payload);
    },
    async selectedBranch(context, payload) {
      payload.branch.interactionTypeId =
        process.env.VUE_APP_INTERACTION_TYPE_WHATSAPP;
      context.commit("setBranchSelected", payload);
    },
    async addToasts(context, payload) {
      context.commit("setToasts", payload);
    },
    async showLoading(context, payload) {
      context.commit("setLoading", payload);
    },
    async resetMouseOver(context) {
      context.commit("resetToggleIsMouseOver");
    },
    async modalAlert(context, payload) {
      context.commit("toggleAlert", payload);
    },
    async modalSelect(context, payload) {
      context.commit("toggleSelectModal", payload);
    },
    async modalButton(context, payload) {
      context.commit("toggleButtonModal", payload);
    },
    async whatsappButton(context, payload) {
      context.commit("toggleVisibleWhatsapp", payload);
    },
    async showChatBox(context, payload) {
      context.commit("toggleVisibleShowChatBox", payload);
    },
    async checkFeedBackInteraccionOpp(context, payload) {
      const cookies = useCookies();
      const user = cookies.cookies.get("user");
      interaccionService
        .checkFeedBackInteraccion(payload.idOportunidad)
        .then((response) => {
          if (response.status != 200) return;
          let interaction = response.data.interactions[0];
          if (interaction) {
            context.commit("toggleModalEstadoContacto", true);
            context.commit("setInteractionSelected", interaction);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async toogleModalEstado(context, payload) {
      context.commit("toggleModalEstado", payload);
    },
    async offModalEstadoContacto(context) {
      context.commit("toggleModalEstadoContacto", false);
      context.commit("setInteractionSelected", {});
    },
    async payloadWhatsapp(context, payload) {
      context.commit("setPayloadWhatsapp", payload);
    },
    async modalCustomerHistory(context, payload) {
      context.commit("toggleCustomerHistoryModal", payload);
    },
    async modalOtherOpp(context, payload) {
      context.commit("toggleOtheOpp", payload);
    },
    async payloadFilterOpportunity(context, payload) {
      context.commit("setFilterOpportunity", payload);
    },
    async payloadFilterApplyOpportunity(context, payload) {
      context.commit("setFilterApplyOpportunity", payload);
    },
    async showModalEstadoContacto(context, payload) {
      context.commit("toggleModalEstadoContacto", payload);
    },
  },
};
