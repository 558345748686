import Swal from "sweetalert2";
import router from "@/router/index";

function eventExistByTitle(eventTitle) {
  const currentPath = router.currentRoute.value.path;

  const roles = JSON.parse(localStorage.getItem("role"));
  const modulesData = roles.applications[0]?.modules;

  if (!modulesData) {
    return router.push({
      path: "/login",
    });
  }

  if (eventTitle) {
    for (const module of modulesData) {
      const foundEvent = module.events.find((ev) => ev.title === eventTitle);

      if (foundEvent) {
        return true;
      }
    }
  }
  return false;
}

function findCorrespondingRoute(to, items) {
  if (to.toLowerCase().includes("/configuracion")) return true;

  const roles = JSON.parse(localStorage.getItem("role"));
  const modulesData = roles?.applications[0]?.modules;

  if (!modulesData) {
    return router.push({
      path: "/login",
    });
  }

  let matchingModule = modulesData.find((module) =>
    to.toLowerCase().includes(module.module.path.toLowerCase())
  );
  let matchingModuleChild = null;
  if (items && Array.isArray(items)) {
    matchingModuleChild = items.find((item) =>
      to.toLowerCase().includes(item.to.toLowerCase())
    );
  }

  if (matchingModule || matchingModuleChild) {
    return true;
  }
  return false;
}

function msgNoAutorizado() {
  Swal.fire({
    icon: "info",
    title: "Acción no autorizada",
    text: "Lo siento, no tienes permisos para realizar esta acción.",
  });
}

export { eventExistByTitle, msgNoAutorizado, findCorrespondingRoute };
