<template>
  <CToaster placement="top-end">
    <CToast v-for="(toast, index) in toasts" :key="index" :visible="true">
      <NotificationTemplate
        class="full-width-height"
        :title="toast.title"
        :dateTime="toast.dateTime"
        :comment="toast.body"
        :smallTitle="toast.smallTitle"
        :idOpp="toast.idOpp"
        :type="toast.type"
      ></NotificationTemplate>
    </CToast>
  </CToaster>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import NotificationTemplate from "@/components/shared/NotificationTemplate.vue";

export default {
  name: "Notification",
  components: {
    NotificationTemplate,
  },
  setup() {
    const store = useStore();
    return {
      toasts: computed(() => store.state.toasts),
    };
  },
};
</script>

<style scoped>
.full-width-height {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>
