<template>
  <div class="container-footer">
    <CFooter v-if="!sidebarUnfoldable" class="footerbar">
      <div class="inverted-border-radius-footer"></div>
    </CFooter>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  name: "AppFooter",
  setup() {
    const store = useStore();
    return {
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
    };
  },
};
</script>
