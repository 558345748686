import ApiMss from "@/api/ApiMss";
import { objectToQueryString } from "@/helper/util";

// servicios expuestos
const productoService = {
  getProductos,
  upsertProductos,
};

async function getProductos(filter) {
  const queryString = objectToQueryString(filter);
  return await ApiMss.get(`/mss-core/v1/products/list?${queryString}`);
}

async function upsertProductos(body) {
  return await ApiMss.post(`/mss-core/v1/products/upsert`, body);
}

export default productoService;
