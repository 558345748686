import getKeyVaultSecret from '../helper/config.js';
import axios from 'axios';
import { ref } from 'vue';
import {useProgress} from '@marcoschulte/vue3-progress';
import router from '@/router/index';
import Swal from 'sweetalert2';
import { useStore } from "vuex";

const progresses = ref([]);

class ApiPPU {
    constructor() {
        const client = getKeyVaultSecret();
        if (!client) {
            const { VUE_APP_API_HOST, VUE_APP_API_KEY, VUE_APP_API_KEY_VALUE_VPP } = process.env;
            this.baseURL = VUE_APP_API_HOST;
            this.headers = {
                [VUE_APP_API_KEY]: VUE_APP_API_KEY_VALUE_VPP
            };
            if (process.env.NODE_ENV == "development") console.info('INIT: ApiMss from env');
            
        } else {
            this.baseURL = client.getSecret('VUE_APP_API_HOST');
            this.headers = {
                [client.getSecret('VUE_APP_API_KEY')]: client.getSecret('VUE_APP_API_KEY_VALUE_VPP')
            };
            if (process.env.NODE_ENV == "development") console.info('INIT: ApiMss from Vault');
        }

        this.instance = axios.create({
            baseURL: this.baseURL
        });
    
        this.instance.interceptors.request.use((config) => {
            if (document.getElementsByClassName("vue3-progress-bar")[0]) 
                document.getElementsByClassName("vue3-progress-bar")[0].style = "background-color: #42b983 !default;";              progresses.value.push(useProgress().start());
            config.headers = {
                ...config.headers,
                ...this.headers
            };
            return config;
        });

        this.instance.interceptors.response.use(
            function (response) {
                progresses.value.pop()?.finish();
                return response;
            },
            function (error) {
                if (document.getElementsByClassName("vue3-progress-bar")[0]) 
                    document.getElementsByClassName("vue3-progress-bar")[0].style = "background-color: red";
                progresses.value.pop()?.finish();
                if (typeof error.response !== 'undefined') {
                    if (error.response.status === 401) {
                        const store = useStore();
                        Swal.fire('Alerta Seguridad', 'Se ha detectado el uso no autorizado de algunos servicios, contacte con su administrador.', 'warning');
                        store.dispatch("logout");
                        return router.push({
                            name: 'Pages',
                        });
                    } 
                }
                return Promise.reject(error);
            }
        );

    }
    getInstance() {
        return this.instance;
    }
}
export default new ApiPPU().getInstance();