import { LogLevel, PublicClientApplication } from "@azure/msal-browser";
import getKeyVaultSecret from "@/helper/config.js";

const system = {
  loggerOptions: {
    loggerCallback: (level, message, containsPii) => {
      if (containsPii) {
        return;
      }
      switch (level) {
        case LogLevel.Error:
          console.error(message);
          return;
        case LogLevel.Info:
          console.info(message);
          return;
        case LogLevel.Verbose:
          console.debug(message);
          return;
        case LogLevel.Warning:
          console.warn(message);
          return;
        default:
          return;
      }
    },
    logLevel: LogLevel.Verbose,
  },
};

const cache = {
  cacheLocation: "localStorage",
};

class ApiMsal {
  constructor() {
    const client = getKeyVaultSecret();
    let msalConfig = null;
    if (!client) {
      const { VUE_APP_PASSPORT_CLIENT_ID, VUE_APP_PASSPORT_AUTHORITY } =
        process.env;
      msalConfig = {
        auth: {
          clientId: "469dfa55-d0bf-43b9-8930-b63525502671",
          authority:
            "https://login.microsoftonline.com/a1f5c915-7c46-4022-b3c6-4c404a9ec5d0",
          redirectUri: "/", // Must be registered as a SPA redirectURI on your app registration
          postLogoutRedirectUri: "/", // Must be registered as a SPA redirectURI on your app registration
        },
        cache,
        system,
      };
      if (process.env.NODE_ENV == "development")
        console.info("INIT: ApiMsal from env");
    } else {
      msalConfig = {
        auth: {
          clientId: client.getSecret("VUE_APP_PASSPORT_CLIENT_ID"),
          authority: client.getSecret("VUE_APP_PASSPORT_AUTHORITY"),
          redirectUri: "/", // Must be registered as a SPA redirectURI on your app registration
          postLogoutRedirectUri: "/", // Must be registered as a SPA redirectURI on your app registration
        },
        cache,
        system,
      };
      if (process.env.NODE_ENV == "development")
        console.info("INIT: ApiMsal from Vault");
    }

    if (msalConfig) this.instance = new PublicClientApplication(msalConfig);
  }
  getInstance() {
    return this.instance;
  }
}
export default new ApiMsal().getInstance();
