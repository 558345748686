import ApiMss from "@/api/ApiMss";

// servicios expuestos
const categoriaService = {
  getCategorias,
  upsertCategoria,
};

async function getCategorias() {
  return await ApiMss.get(`/mss-core/v1/categories/list`);
}

async function upsertCategoria(body) {
  return await ApiMss.post(`/mss-core/v1/categories/upsert`, body);
}

export default categoriaService;
