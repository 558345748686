<template>
  <CCallout color="primary" class="p-2">
    <CContainer fluid class="d-flex">
      <AlarmClock v-if="type == 'schedule'" class="align-self-center" />
      <PhoneCall v-else-if="type == 'call'" class="align-self-center" />
      <MessageCircleMore
        v-else-if="type == 'whatsapp'"
        class="align-self-center"
      />
      <AlertCircle v-else-if="type == 'alert'" class="align-self-center" />
      <CContainer fluid class="d-grid">
        <small class="text-xs-medium-gray text-muted">
          {{ origin }}
        </small>
        <CRow>
          <CCol :md="6">
            <b class="text-nowrap">{{ title }}</b>
          </CCol>
          <CCol :md="6" class="text-end">
            <CFormLabel class="callout-fecha">
              {{
                !dateTime
                  ? formattedDate(new Date(), "H:mm [hrs] - ddd D, MMM")
                  : formattedDate(dateTime, "H:mm [hrs] - ddd D, MMM")
              }}
            </CFormLabel>
          </CCol>
        </CRow>
        <small class="text-xs-medium-gray"> {{ comment }} </small>
        <CRow v-if="idOpp" class="pt-2">
          <CCol class="text-center d-grid">
            <CButton @click="ir" class="btn-primary text-sm-medium"
              ><ArrowUpRightFromSquare size="20"
            /></CButton>
          </CCol>
        </CRow>
      </CContainer>
    </CContainer>
  </CCallout>
</template>

<script>
import { toRefs } from "vue";
import { formattedDate } from "@/helper/util.js";
import {
  AlarmClock,
  ArrowUpRightFromSquare,
  PhoneCall,
  MessageCircleMore,
  AlertCircle,
} from "lucide-vue-next";
import router from "@/router/index";

export default {
  name: "NotificationTemplate",
  props: {
    type: {
      require: false,
      type: String,
    },
    smallTitle: {
      require: false,
      type: String,
    },
    title: {
      require: true,
      type: String,
    },
    dateTime: {
      type: String,
      value: Date.now.toString(),
      require: false,
    },
    comment: {
      require: true,
      type: String,
    },
    idOpp: {
      require: false,
      type: String,
    },
    origin: {
      require: false,
      type: String,
    }
  },
  components: {
    AlarmClock,
    ArrowUpRightFromSquare,
    PhoneCall,
    MessageCircleMore,
  },
  setup(props) {
    const { type, smallTitle, title, dateTime, comment, idOpp, origin } = toRefs(props);

    const ir = () => {
      if (!idOpp) return;
      let fromNot = false;
      if (type.value == "whatsapp") fromNot = true;
      return router.push({
        name: "Oportunidad",
        params: { id: idOpp.value, fromNot: fromNot },
      });
    };

    return {
      type,
      smallTitle,
      title,
      dateTime,
      comment,
      idOpp,
      origin,
      formattedDate,
      ir,
    };
  },
};
</script>

<style lang="scss" scoped></style>
