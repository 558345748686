<template>
  <CModal
    alignment="center"
    :visible="visibleModalSelect"
    @close="
      () => {
        $store.commit('resetSelectModal');
      }
    "
  >
    <CModalBody class="p-4">
      <CRow>
        <CCol class="selectmodal-container">
          <CRow>
            <CCol v-if="selectContent.icon == 'success'">
              <CImage :src="contacto" />
            </CCol>
            <CCol
              class="selectmodal-container-icon-error"
              v-if="selectContent.icon == 'error'"
            >
              <PhoneMissed class="selectmodal-icon-error" />
            </CCol>
          </CRow>
          <CFormLabel class="fw-bold"> {{ selectContent.content }} </CFormLabel>
        </CCol>
      </CRow>
      <CRow class="pt-3">
        <CCol>
          <CFormSelect @change="selectOnChange" size="sm">
            <option :key="0" disabled value="">
              {{ selectContent.placeholder }}
            </option>
            <option
              v-for="value in selectContent.values"
              :value="value.id"
              :key="value.id"
            >
              {{ value.name }}
            </option>
          </CFormSelect>
        </CCol>
      </CRow>
    </CModalBody>
  </CModal>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import contacto from "@/assets/icons/contacto.svg";
import { PhoneMissed } from "lucide-vue-next";

export default {
  components: {
    PhoneMissed,
  },
  setup() {
    const store = useStore();
    return {
      contacto,
      selectModalContent: computed(() => store.state.selectModalContent),
      visibleModalSelect: computed(() => store.state.visibleModalSelect),
    };
  },
};
</script>
