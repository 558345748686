// import { SecretClient } from '@azure/keyvault-secrets';
// import { DefaultAzureCredential } from '@azure/identity';

export default function getKeyVaultSecret() {
  try {
    // const vaultUrl = `https://indumotora-keys.vault.azure.net/`;
    // // const credential = {
    // //     clientId: env.clientId,
    // //     clientSecret: env.clientSecret,
    // //     tenantId: 'kiachile',
    // // };
    // const credential = new DefaultAzureCredential();
    // const client = new SecretClient(vaultUrl, credential);
    return null;
  } catch (error) {
    console.error(`Error getting config from vault.azure: ${error}`);
    return null; 
  }
}